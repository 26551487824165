<template>
    <div
        :class="{ 'instructor-section__bg--redesign': enableRedesign && !memberView,
                  'instructor-section__bg--redesign-edit': enableRedesign && memberView}"
    >
        <section
            v-if="editMode || videos.length"
            class="instructor-section"
            :class="{ 'instructor-section--redesign': enableRedesign }"
        >
            <div
                class="instructor-section__header"
                :class="{ 'instructor-section__header--redesign': enableRedesign && !editMode,
                          'instructor-section__header--redesign-edit': enableRedesign && editMode }"
            >
                <h4
                    v-if="enableRedesign"
                    class="instructor-section__title--redesign z-h1"
                >
                    {{ t.videoOnDemand }}
                </h4>
                <h5
                    v-else
                    class="instructor-section__title f-inter"
                >
                    {{ t.videoOnDemand }}
                </h5>
                <a
                    v-if="editMode"
                    class="vods-edit-link"
                    :href="editLink"
                >
                    <PencilIcon />
                </a>
                <div
                    v-if="enableRedesign && editMode"
                    class="break--redesign"
                />
                <div
                    class="instructor-section__filters"
                >
                    <a
                        v-if="videos.length && !hideFilters"
                        class="vods-filter"
                        :class="{ 'vods-filter--redesign': enableRedesign }"
                        @click.prevent="showFilters = true"
                    >
                        <div
                            v-if="filterCount > 0"
                            class="vods-list__filter-count z-b5"
                        >
                            {{ filterCount }}
                        </div>
                        <FilterSvg />
                    </a>
                </div>
            </div>
            <div
                v-if="visibleVideos.length"
                class="vods-list"
            >
                <VODItem
                    v-for="video in visibleVideos"
                    :id="video.id"
                    :key="video.id"
                    :title="video.title"
                    :photo="video.video_photo"
                    :duration="video.video_duration"
                    :price="video.price"
                    :currency="video.price_currency"
                    :specialties="video.fitness_specialties"
                    :all-specialties="specialties"
                    :vods-action="video.url"
                    :details-url="video.detailsUrl"
                    :buy-url="video.buyUrl"
                    :enable-redesign="enableRedesign"
                    :t="t"
                />
            </div>
            <div
                v-else-if="videos?.length"
            >
                <div class="vods-list--empty-container f-inter">
                    {{ t.noVODSAvailable }}
                    <a
                        class="z-b3 f-inter"
                        @click.prevent="clearFilters()"
                    >
                        {{ t.viewFullSchedule }}
                    </a>
                </div>
            </div>
            <div
                v-else
                class="vods-list--empty-container f-inter"
            >
                <div class="z-b3">
                    {{ t.noVideosPosted }}
                </div>
                <a
                    class="z-b3"
                    :href="addLink"
                >
                    {{ t.add }}
                </a>
            </div>
            <div
                v-if="filteredClasses.length > visibleRecords"
                :class="{ 'vods-list--show-more--redesign': enableRedesign, 'vods-list--show-more': !enableRedesign }"
            >
                <ZButton
                    v-if="enableRedesign"
                    variety="optimus"
                    wide="never"
                    @click.prevent="visibleRecords += 3"
                >
                    {{ t.showMore }}
                </ZButton>
                <a
                    v-else
                    class="f-inter"
                    @click.prevent="visibleRecords += 3"
                >
                    {{ t.showMore }}
                </a>
            </div>
            <ClassFilterModal
                :t="t"
                :show="showFilters"
                :specialties="specialties"
                :available-filters="availableFilters"
                @apply="(filters) => selectedFilters = filters"
                @closed="showFilters = false"
            />
        </section>
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ComputedRef, Ref, computed, ref } from 'vue';
import ClassFilterModal from '@components/ClassListing/ClassFilterModal.vue'
import VODItem from './VODItem.vue'
import FilterSvg from '@bx-icons/regular/bx-filter.svg'
import PencilIcon from '@bx-icons/regular/bx-pencil.svg'
import ZButton from "@components/Core/ZButton.vue"

type VideoItem = {
    id: string
    title: string
    video_photo: string
    video_duration: string
    price: string,
    price_currency: string
    fitness_specialties: string[]
    url: string,
    detailsUrl: string,
    buyUrl: string
};

type VODFilters = {
    durations: string[],
    specialties: string[],
};

export default defineComponent({
    components: {
        VODItem,
        FilterSvg,
        PencilIcon,
        ClassFilterModal,
        ZButton
    },
    props: {
        t: {
            type: Object,
            default: () => ({
                title: '',
            })
        },
        editLink: {
            type: String,
            default: () => ''
        },
        addLink: {
            type: String,
            default: () => ''
        },
        editMode: {
            type: Boolean,
            default: () => false
        },
        specialties: {
            type: Object,
            default: () => {}
        },
        hideFilters: {
            type: Boolean,
            default: false
        },
        pageSize: {
            type: Number,
            default: 6
        },
        videos: {
            type: Array as PropType<VideoItem[]>,
            default: () : Array<VideoItem> => []
        },
        enableRedesign: {
            type: Boolean,
            default: false
        },
        memberView: {
            type: Boolean,
            default: false
        },
    },
    setup (props) {
        const showFilters: Ref<boolean> = ref(false)
        const selectedFilters = ref<VODFilters>({
            durations: [],
            specialties: [],
        });

        let durationsFilter: string[] = [], specialtiesFilter: string[] = [];
        props.videos.forEach((video) => {
            let duration;
            let video_duration = parseInt(video.video_duration);
            specialtiesFilter = specialtiesFilter.concat(video.fitness_specialties);

            if (video_duration < 1860) {
                duration = 'underThirty';
            } else if (video_duration <= 3600) {
                duration = 'thirtySixty';
            } else {
                duration = 'overSixty';
            }

            if (!durationsFilter.includes(duration)) {
                durationsFilter.push(duration);
            }
        });

        const availableFilters: Ref<Object> = ref<VODFilters>({
            durations: durationsFilter,
            specialties: [...new Set(specialtiesFilter)],
        });

        const filteredClasses: ComputedRef<VideoItem[]> = computed(() => {
            let { durations, specialties } = selectedFilters.value;
            return props.videos.filter((video) => {
                if (durations.length &&
                    durations.every((d: any) => d.min > video.video_duration || d.max < video.video_duration)
                ) {
                    return false
                }

                if (
                    specialties.length &&
                    video.fitness_specialties.every(s => !specialties.includes(s))
                ) {
                    return false
                }
                return true;
            });
        });

        const visibleRecords: Ref<number> = ref(props.pageSize)
        const visibleVideos: ComputedRef<VideoItem[]> = computed(() => {
            return filteredClasses.value.slice(0, visibleRecords.value);
        });

        const filterCount: ComputedRef<number> = computed(() => {
            let { durations, specialties } = selectedFilters.value;
            return durations.length + specialties.length;
        })

        const clearFilters = () => {
            selectedFilters.value = {
                durations: [],
                specialties: [],
            };
        };

        return {
            filteredClasses,
            selectedFilters,
            availableFilters,
            showFilters,
            visibleRecords,
            visibleVideos,
            filterCount,
            clearFilters,
        }
    }
})

</script>
<style scoped>
.instructor-section {
    min-height: 10.6rem;
}

.instructor-section__title {
        margin: 0;
}

.theme-strong .instructor-section__title {
    font-family: var(--strong-font-default);
}

.theme-strong .instructor-section__title--redesign {
    font-family: var(--strong-font-uniform);
}

.instructor-section__bg--redesign {
    background-color: var(--zumba-white);
    margin: 0 -1rem;
}
.instructor-section__bg--redesign-edit {
    background-color: var(--zumba-white);
    margin: 0;
}

.instructor-section--redesign {
    padding: 2rem 1rem 2.5rem;
    margin: 0;
}

.instructor-section__header {
    display: flex;
    align-items: center;
    padding-bottom: 1rem;
}

.instructor-section__header--redesign {
    padding-bottom: 1.5rem;
}

.instructor-section__filters {
    display: flex;
    margin-left: auto;
}

.vods-edit-link {
    align-items: center;
    display: flex;
    margin-left: 0.5rem;
}

.vods-edit-link svg {
    width: 1.125rem;
    height: 1.125rem;
}

.vods-filter {
    border: 1px solid var(--zumba-plum);
    border-radius: .125rem;
    fill: var(--zumba-plum);
    height: 2.5rem;
    margin-left: 0.5rem;
    position: relative;
    width: 2.5rem;
    padding: .5rem .5rem .25rem .5rem;
}

.themed-strong .vods-filter {
    border: 1px solid var(--strong-razzmatazz);
}

.theme-strong .vods-filter--redesign {
    border: 1px solid var(--strong-razzmatazz);
    fill: var(--strong-razzmatazz);
}

.vods-list__filter-count {
        background: var(--zumba-plum);
        border-radius: 100%;
        color: var(--zumba-white);
        display: block;
        font-weight: 500;
        height: 1rem;
        line-height: 1rem;
        position: absolute;
        right: -0.5rem;
        text-align: center;
        top: -0.5rem;
        width: 1rem;
    }

.theme-strong .vods-list__filter-count {
    background: var(--strong-razzmatazz);
}

.theme-zumba .vods-list__filter-count {
    background: var(--zumba-plum);
}

.vods-list--show-more {
    margin-top: 1.5rem;
    text-align: center;
}
.vods-list--show-more a {
    color: var(--zumba-plum);
    display: block;
}
.vods-list--show-more--redesign {
    margin-top: .5rem;
    display: flex;
    justify-content: center;
}

.theme-strong .vods-list--show-more a {
    color: var(--strong-razzmatazz);
}

.vods-list--empty-container {
    align-items: center;
    display: flex;
    flex-grow: 1;
    justify-content: center;
}

.vods-list--empty-container a {
    color: var(--zumba-plum);
    margin-left: 0.5rem;
}

@media all and (min-width: 48rem) {
    .vods-list {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -0.5rem;
    }
}

@media screen and (min-width: 48em) {
    .instructor-section--redesign {
        padding: 3.75rem 1rem;
        margin: 0 auto 2.5rem;
    }
    .instructor-section__header--redesign {
        flex-direction: column;
        padding-right: .5rem;
    }
    .instructor-section__header--redesign-edit {
        flex-wrap: wrap;
        justify-content: center;
        padding-right: .5rem;
    }
    .break--redesign {
        flex-basis: 100%;
        height: 0;
    }
    .vods-filter--redesign {
        margin-top: 1.5rem;
    }
    .theme-strong .instructor-section__title--redesign {
        font-size: 3.125rem;
    }
}
@media screen and (max-width: 48em) {
    .instructor-section__title--redesign{
        font-size: 1.875rem;
        margin-bottom: 0;
    }
    .theme-strong .instructor-section__title--redesign {
        font-size: 2.25rem
    }

    .theme-zumba .instructor-section__title--redesign{
            font-weight: 700;
        }
}
</style>
