<template>
    <div
        ref="root"
        class="container"
        :class="{'f-inter': isZumbaThemed}"
        role="combobox"
        :aria-owns="'listbox-' + id"
        :tabindex="tabindex"
        @blur="blur"
        @click.prevent="toggleSelections"
        @keyup.esc="close"
        @keyup.down="open"
    >
        <input
            :id="id"
            :name="name"
            :value="modelValue"
            :disabled="disabled"
            type="hidden"
        >
        <div
            v-if="label"
            class="form-select-label"
        >
            {{ label }}
        </div>
        <div
            class="select"
            :class="{
                dirty: interacted,
                error: error.length > 0,
                selected: modelValue,
                labeled: !!label.length,
                disabled,
                selecting,
            }"
            :style="selectStyleOverrides"
        >
            <span
                :class="['selected-text', {'bubble': selectedBubble}]"
            >
                {{ selectedText }}
            </span>
            <chevron-svg
                v-if="includeChevron"
                class="chevron"
            />
        </div>
        <transition name="drop-top">
            <div
                v-if="optionsOpen"
                class="options-container"
            >
                <div
                    class="options"
                    role="listbox"
                    :class="[scrollClass, scrollbarClass]"
                >
                    <div
                        v-for="(properties, text, index) in dropdownOptions"
                        :key="index"
                        class="option-container"
                        :class="{
                            [`content-align-${itemAlignment}`]: itemAlignment,
                        }"
                        role="option"
                    >
                        <a
                            :class="properties.classes"
                            :aria-disabled="properties.disabled"
                            @click.prevent.stop="onSelect(properties)"
                        >
                            <div>{{ text }}</div>
                            <template v-if="properties.description.length">
                                <div class="dropdown-description">{{ properties.description }}</div>
                            </template>
                        </a>
                    </div>
                    <div
                        v-if="footer"
                        class="footer"
                    >
                        {{ footer }}
                    </div>
                </div>
            </div>
        </transition>
        <p class="error-msg">
            {{ error }}
        </p>
    </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, Ref, ref } from 'vue'
import { v4 as uuid } from 'uuid'
import ChevronSvg from '@bx-icons/regular/bx-chevron-down.svg'
import { theme, ThemeType } from "@ts/Util/theme";

interface LabeledValue {
    label: string
}

interface LabeledValueWithDescription extends LabeledValue {
    description: string
}

const isLabeledValue = (object: any): object is LabeledValue => typeof object === 'object' && 'label' in object

const isLabeledValueWithDescription = (object: any): object is LabeledValueWithDescription =>
    isLabeledValue(object) && 'description' in object

interface LabeledOption {
    key: string
    label: string
    description: string
    classes: string[]
    disabled: boolean
}

export type SelectOptions = {
    [key: string]: string
}

/**
 * Note: This component works with `v-model` so you can use `<form-select v-model="inputVar" ... />` to bind the
 * selected value to some local var.
 */
export default defineComponent({
    name: 'FormSelect',
    components: {
        ChevronSvg,
    },
    props: {
        name: {
            type: String,
            required: true
        },
        items: {
            type: Object as PropType<Record<string, LabeledValue|string|number>>,
            required: true,
        },
        placeholder: {
            type: String,
            default: ''
        },
        label: {
            type: String,
            default: '',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        footer: {
            type: String,
            default: '',
        },
        error: {
            type: String,
            default: ''
        },
        blurOnClose: {
            type: Boolean,
            default: true
        },
        /**
         * Used by vue to bind the input value to `v-model` set on the component.
         */
        modelValue: {
            type: String,
            default: null,
        },
        selectStyleOverrides: {
            type: Object,
            default: () : Object => ({})
        },
        includeChevron: {
            type: Boolean,
            default: true
        },
        itemAlignment: {
            type: String as PropType<'left'|'center'|'right'>,
            default: 'left',
            validator: ( value : string ) => {
                return ['left', 'center', 'right'].includes(value)
            }
        },
        tabindex: {
            type: Number,
            default: 0
        },
        scrollAfter: {
            type: Number,
            default: 0,
        },
        bubbleItems: {
            type: Array,
            default: (): Array<String> => {
                return [];
            }
        },
        /**
         * Keeps the select enabled
         * but allows to disable individual items
         */
        disableItems: {
            type: Array,
            default: () : Array<string>=> []
        },

        /**
         * These two props allow using the select in a somewhat passive fashion
         * ie. controlling from a parent. (we can still model bind and listen to events)
         * note `passiveOpen` will never have any effect if `passive` is false
         */
        passive: {
            type: Boolean,
            default: false
        },
        passiveOpen: {
            type: Boolean,
            default: false
        },

        /**
         * Forces the browser to show a scrollbar on the dropdown
         */
        forceShowScrollbar: {
            type: Boolean,
            default: false
        },
        displayValueOverride: {
            type: String,
            default: ''
        }
    },
    emits: ['update:model-value'],
    setup(props, {emit}) {
        const isZumbaThemed = theme.value === ThemeType.Zumba;
        const selectedBubble = ref(false)
        const root: Ref<null|HTMLElement> = ref(null)
        const interacted = ref(false)
        const id = ref(uuid())
        const selecting = ref(false)
        const scrollClass = computed(() => {
            return props.scrollAfter > 0 &&
                Object.keys(props.items).length > props.scrollAfter ? 'scroll' : ''
        })
        const blur = (event) => {
            if (event.relatedTarget && (
                event.relatedTarget === root.value ||
                root.value?.contains(event.relatedTarget)
            )) {
                return
            }
            close()
        }
        const scrollbarClass = computed(() => {
            return props.forceShowScrollbar ? 'force-scrollbar' : ''
        })

        const optionsOpen = computed(() => {
            if (props.disabled) {
                return false
            }
            if (props.passive) {
                return props.passiveOpen
            }
            return selecting.value
        })

        const dropdownOptions = computed(() => {
            const options: Record<string, LabeledOption> = {}

            for (const key in props.items) {
                const option = {
                    key,
                    label: '',
                    description: '',
                    classes: ['item-link'],
                    disabled: props.disableItems.includes(key),
                    bubble: props.bubbleItems.includes(key),
                }
                if (key === props.modelValue) {
                    option.classes.push('selected')
                }
                if (option.bubble) {
                    option.classes.push('bubble')
                }
                if (option.disabled) {
                    option.classes.push('disabled')
                }
                if (isZumbaThemed) {
                    option.classes.push('f-inter')
                }
                const item = props.items[key]
                if (isLabeledValue(item)) {
                    option.label = item.label
                    if (isLabeledValueWithDescription(item)) {
                        option.description = item.description
                    }
                } else if (['string', 'number'].includes(typeof item)) {
                    // Value is scalar
                    option.label = item.toString()
                } else {
                    throw Error(`Unsupported value type for option ${key}`)
                }
                if (option.description.length > 0) {
                    option.classes.push('multi-line')
                }
                options[option.label] = option
            }
            return options
        })

        const selectedText = computed(() => {
            if (props.displayValueOverride) {
                return props.displayValueOverride
            } else {
                const selected = Object.values(dropdownOptions.value).filter(option => {
                    return option.key === props.modelValue
                })
                if (selected.length) {
                    updateSelectedBubble(selected[0].classes.includes('bubble'))
                }
                return selected.length > 0 ? selected[0].label : props.placeholder || ''
            }
        })

        const updateSelectedBubble = (include : boolean) => {
            selectedBubble.value = include
        }

        const toggleSelections = () => {
            if (props.disabled) {
                return
            }
            selecting.value ? close() : open()
        }

        const open = () => {
            if (props.disabled) {
                return
            }
            root.value?.focus()
            selecting.value = true
        }

        const close = () => {
            if (props.disabled) {
                return
            }

            if (props.blurOnClose) {
                root.value?.blur()
            }
            selecting.value = false
        }

        const onSelect = (item: LabeledOption) => {
            interacted.value = true
            close()
            emit('update:model-value', item.key)
        }

        return {
            root,
            interacted,
            id,
            selecting,
            scrollClass,
            scrollbarClass,
            optionsOpen,
            dropdownOptions,
            selectedText,
            selectedBubble,
            isZumbaThemed,
            toggleSelections,
            open,
            close,
            onSelect,
            blur
        }
    },
})
</script>

<style scoped>
.container {
    position: relative;
    --select-height: 3.12rem;
}

.select {
    cursor: pointer;
    height: var(--select-height);
    width: 100%;
    border: .0625rem solid var(--zumba-gray-200);
    border-radius: .125rem;
    background-color: var(--zumba-white);
    padding: 0 1rem;
    font-size: 1rem;
    font-weight: normal;
    color: var(--zumba-gray-600);
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.selecting {
    border: 1px solid var(--zumba-gray-800);
}

.select.labeled {
    padding-left: 0.75rem;
}

.labeled > .selected-text {
    padding-top: 0.9rem;
}

.select.labeled::after {
    position: absolute;
    right: 1.5rem;
    top: 1.5rem;
}

.selected {
    color: var(--zumba-gray-600);
}

.select.disabled {
    cursor: not-allowed;
    color: var(--zumba-gray-400);
}

.select.disabled::after {
    border-bottom: .2rem solid var(--zumba-gray-400);
    border-left: .2rem solid var(--zumba-gray-400);
}

.select.error {
    border: 1px solid var(--zumba-error-red);
}

.selected-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: calc(100% - .8rem);
}

.form-select-label {
    position: absolute;
    top: 1.5rem;
    left: 0;
    width: 100%;
    transition: 0.2s;
    color: var(--zumba-gray-400);
    font-weight: normal;
    padding-left: 0.85rem;
    bottom: 100%;
    margin-top: -1rem;
    font-size: .75rem;
}

.chevron {
    fill: var(--zumba-gray-600);
}

.error-msg {
    margin: .25rem 0 .5rem .25rem;
    color: var(--zumba-error-red);
    font-size: .75rem;
}

.options-container {
    position: absolute;
    min-width: 100%;
    max-width: fit-content;
    left: 0;
    transition: all 0.1s ease-in-out;
    transform-origin: top;
    z-index: 2;
}

.options {
    font-weight: normal;
    font-size: .875rem;
    padding: 1.5rem;
    border: 1px solid var(--zumba-gray-200);
    background-color: var(--zumba-white);
    overflow-y: auto;
}

.options.scroll {
    max-height: 10rem;
}

.options.scroll.force-scrollbar::-webkit-scrollbar {
    -webkit-appearance: none;
}

.options.scroll.force-scrollbar::-webkit-scrollbar:vertical {
    width: .75rem;
}

.options.scroll.force-scrollbar::-webkit-scrollbar-thumb {
    border: 2px solid var(--zumba-white);
    background-color: var(--zumba-gray-400);
}

.option-container {
    margin: 0;
    display: flex;
    align-items: center;
}

.option-container:nth-child(n+2) {
    padding-top: 1rem;
}

.content-align-center {
    justify-content: center;
}

.content-align-left {
    justify-content: left;
}

.content-align-right {
    justify-content: right;
}

.item-link {
    text-decoration: none;
    color: var(--zumba-gray-600);
    display: flex;
    align-items: center;
    white-space: nowrap;
    flex-grow: 1;
}

.item-link:hover {
    color: var(--zumba-plum);
}

.item-link.disabled,
.item-link.disabled:hover {
    color: var(--zumba-gray-400);
    cursor: not-allowed;
}

.item-link.bubble,
.selected-text.bubble {
    position:relative;
}

.item-link.bubble::after,
.selected-text.bubble::after {
    content: '';
    width: .375rem;
    height: .375rem;
    background-color: var(--zumba-error-red);
    border-radius: 3rem;
    margin-left: .125rem;
}

.selected-text.bubble::after {
    display: inline-block;
    position: absolute;
    top: -0.01rem;
}

.item-link.multi-line {
    flex-direction: column;
}

.item-link.selected {
    color: var(--zumba-gray-400);
}

.content-align-left .item-link {
    align-items: flex-start;
}

.dropdown-description {
    font-weight: normal;
    white-space: normal;
}

.footer {
    margin-top: .75rem;
    margin-bottom: .5rem;
    padding-top: .75rem;
    border-top: solid 1px var(--zumba-gray-200);
    font-size: .8rem;
    color: var(--zumba-gray-400);
    text-align: center;
}

.drop-top-enter-from,
.drop-top-leave-to {
    opacity: 0;
    transform: translateY(0) scaleY(0);
}

.theme-strong .item-link:hover {
    color: var(--strong-primary-coral);
}
</style>
