<template>
    <div
        v-if="show"
        class="view-as-banner f-inter"
        :class="{ absolute }"
    >
        <div class="view-as-banner__content">
            <Eye />
            <span>{{ message }}</span>
        </div>
        <a
            href="#"
            class="f-inter"
            @click.prevent="viewAs"
        >
            {{ cta }}
        </a>
    </div>
</template>
<script setup lang="ts">
import Eye from '@icons/eye.svg';
import { computed, Computed } from 'vue';
import { csrfToken } from '@ts/Util/security'
import { post } from '@ts/Util/http'

const props = defineProps({
    show: {
        type: Boolean,
        required: true,
    },
    viewAsClient: {
        type: Boolean,
        required: true,
    },
    action: {
        type: String,
        default: '',
    },
    t: {
        type: Object,
        default: () => ({
        })
    },
    absolute: {
        type: Boolean,
        default: true,
    },
})

const emit = defineEmits(['view-as:clicked'])

const message: Computed<string> = computed(() => {
    return props.viewAsClient ? props.t.yourself : props.t.student;
});

const cta: Computed<string> = computed(() => {
    return props.viewAsClient ? props.t.viewAsYourself : props.t.viewAsStudent;
});

const viewAs = async () => {
    if (!props.action) {
        emit('view-as:clicked')
        return;
    }
    const viewAsClient = !props.viewAsClient;
    const response = await post(props.action, csrfToken(), {
        viewAsClient,
    });

    if (response.ok) {
        const url = new URL(location.href);
        viewAsClient ? url.searchParams.set('client', '') : url.searchParams.delete('client');

        window.location.href = url.href.replace('=', '');
    }
};

</script>
<style scoped>
.view-as-banner {
    align-items: center;
    background: var(--zumba-light-purple);
    color: var(--zumba-white);
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 -1rem;
    min-height: 4.875rem;
    padding: 1rem;
    text-align: center;
}

.view-as-banner__content {
    display: flex;
}

.view-as-banner__content span {
    margin: 0 0.5rem;
}

.view-as-banner a {
    color: var(--zumba-white);
    font-weight: 700;
    margin-top: 0.5rem;
    text-decoration: underline;
}

.view-as-banner a:hover {
    text-decoration: none;
}

.absolute {
    left: 0;
    margin: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

@media only screen and (min-width: 48rem) {
    .view-as-banner {
        flex-direction: row;
        min-height: 0;
    }

    .view-as-banner__content {
        align-items: center;
    }

    .view-as-banner a {
        margin-top: 0;
    }
}

</style>