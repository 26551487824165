<template>
    <Modal
        :show="show"
        show-close-button
        mobile-drawer
        close-on-mask
        @closed="$emit('closed')"
    >
        <div class="rating-modal-content">
            <h4
                class="rating__title f-inter"
            >
                {{ title }}
            </h4>
            <div v-if="mode === 'edit' || state.isSubmitted">
                <Rating
                    :model-value="state.rating"
                    class="rating"
                    :star-size="32"
                    :read-only="true"
                />
                <p class="rating-date">
                    {{ humanizedRatingDate }}
                    <a
                        href="javascript:void(0)"
                        @click="edit()"
                    >
                        {{ t.edit }}
                    </a>
                </p>
            </div>
            <div v-else-if="mode === 'rate'">
                <Rating
                    :model-value="state.rating"
                    class="rating"
                    :star-size="32"
                    :read-only="disabled || state.isSubmitted"
                    @rating:rated="handleRatingSelect($event)"
                />
                <div
                    v-show="showReview"
                    class="review-section"
                >
                    <horizontal-separator />
                    <p class="p-medium review-section__header">
                        <b>{{ t.reviewHeader }}</b>
                    </p>
                    <p class="p-caption review-section__body">
                        {{ t.reviewBody }}
                    </p>
                    <TextArea
                        v-model="state.comment"
                        :disabled="disabled"
                        :rows="5"
                        :maxlength="255"
                        :label="t.reviewPlaceHolder"
                        name="comment"
                        @focus="scrollTextIntoView"
                    />
                    <ZButton
                        class="review-section__submit"
                        primary
                        :disabled="disabled"
                        @click.prevent="storeRating()"
                    >
                        {{ t.submit }}
                    </ZButton>
                    <div class="cancel-section">
                        <a
                            class="cancelTip"
                            @click.prevent="$emit('closed')"
                        >
                            {{ t.cancel }}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </Modal>
</template>

<script lang="ts">
import { defineComponent, reactive, computed, ref, Ref, PropType } from 'vue'
import Modal from "@components/Core/Modal.vue";
import { validateTranslations } from "@ts/Util/i18n";
import Rating from "@modules/Rating/Rating.vue";
import { interpolate } from "@ts/Util/text";
import HorizontalSeparator from "@components/Core/HorizontalSeparator.vue";
import TextArea from "@components/Core/TextArea.vue";
import ZButton from "@components/Core/ZButton.vue";
import { useSafariMobileKeyboard } from "@composables/safariMobileKeyboard";

const localT = {
    rateYourExperience: 'Rate your experience with {0}',
    instructorRating:  'Instructor rating',
    ratingSubmitted: 'Rating submitted',
    reviewHeader: 'Leave a Review',
    reviewBody: 'Share a review about this instructor.',
    reviewPlaceHolder: 'How was their class, their coaching style?',
    ratingSubmittedOn: 'Rating submitted {0} at {1}',
    skipForNow: 'Skip for now',
    submit: 'Submit',
    cancel: 'Cancel',
    edit: 'Edit',
};

export type RateInstructorMode = 'rate' | 'edit' | 'tip';

export type RateData = {
    instructorPID: string,
    rating: number,
    comment: string
    date?: Date
}
export default defineComponent({
    name: 'RateInstructor',
    components: {
        Modal,
        ZButton,
        TextArea,
        HorizontalSeparator,
        Rating,
    },
    props: {
        t: {
            type: Object,
            default: () : Object => localT,
            validator: (value : Object) => validateTranslations(value, localT),
        },
        show: {
            type: Boolean,
            default: false
        },
        instructorPid: {
            type: String,
            required: true
        },
        instructorName: {
            type: String,
            required: true,
        },
        rating: {
            type: [Number, String],
            default: null,
            validate: (value : number | string | null) : boolean => {
                return value === null || (+value > 0 && +value < 6)
            }
        },
        currency: {
            type: String,
            default: 'USD',
        },
        tipOptions: {
            type: Object as PropType<Number[]>,
            required: true,
        },
        mode: {
            type: String as PropType<RateInstructorMode>,
            default: 'rate',
            validate: (value : string) : boolean => {
                return ['rate', 'tip'].includes(value)
            }
        },
        disabled: {
            type: Boolean,
            default: false
        },
        ratingSubmitted: {
            type: String as PropType<string | null>,
            default: null
        }
    },
    emits: [
        'rate-instructor:rate',
        'rate-instructor:tip',
        'closed',
        'rate-instructor:edit',
    ],
    setup (props, { emit }) {
        const state = reactive({
            rating: Boolean(props.rating) ? Number(props.rating) : 0,
            comment: '' as string,
            isSubmitted: Boolean(props.ratingSubmitted)
        })

        const showReview = computed(() => {
            return !!state.rating && !state.isSubmitted
        })

        const title = computed(() => {
            return interpolate(props.t.rateYourExperience, [props.instructorName])
        })

        const humanizedRatingDate = computed(() : string => {
            if (!props.ratingSubmitted)  {
                return ''
            }

            const dateOptions: Intl.DateTimeFormatOptions = {
                month: 'short',
                day: 'numeric',
            }

            const timeOptions: Intl.DateTimeFormatOptions = {
                hour: 'numeric',
                minute: 'numeric',
            }
            const date = new Date(props.ratingSubmitted);
            const dateFormatted = new Intl.DateTimeFormat(window.navigator.language, dateOptions)
                .format(date)
            const timeFormatted = new Intl.DateTimeFormat(window.navigator.language, timeOptions)
                .format(date)

            return interpolate(props.t.ratingSubmittedOn, [dateFormatted, timeFormatted])
        })

        const handleRatingSelect = (rating : number) => {
            state.rating = rating
        }

        const { scrollTextIntoView } = useSafariMobileKeyboard()

        return {
            state,
            title,
            showReview,
            handleRatingSelect,
            humanizedRatingDate,
            storeRating() {
                emit('rate-instructor:rate', {
                    rating: state.rating,
                    instructorPID: props.instructorPid,
                    comment: state.comment,
                    date: new Date(),
                } as RateData)
            },
            edit() {
                state.rating = 0
                state.isSubmitted = false
                emit('rate-instructor:edit')
            },
            scrollTextIntoView,
        }
    },
})
</script>

<style scoped>
.rating-modal-content {
    box-sizing: border-box;
}

@media screen and (min-width: 48rem) {
    .rating-modal-content {
        width: 34.25rem;
        padding: 1rem 3rem;
    }

    .cancel-section {
        padding-bottom: 0;
    }
}

.rating__title {
    font-size: 1.25rem;
    margin-bottom: 1rem;
    font-weight: 700;
    text-align: center;
}

:deep(.rating) {
    margin: 1.25rem 0;
}
.review-section {
    text-align: center;
}
.review-section__header {
    margin-bottom: 1rem;
    padding-bottom: 0;
}
.review-section__body {
    margin-bottom: 1rem;
}
.review-section__submit {
    margin-top: .75rem;
}

.rating-date {
    text-align: center;
}

.cancel-section {
    color: var(--zumba-light-pink);
    text-align: center;
    margin-top: 1rem;
    cursor: pointer;
    padding-bottom: .375rem;
}
</style>
