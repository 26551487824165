<template>
    <div
        v-if="loading"
        class="paypal-tip-body"
    >
        <Loading />
    </div>
    <div
        v-else
        class="paypal-tip-body"
    >
        <div class="paypal-tip-title">
            <div class="paypal-tip-title-header">
                {{ tipHeader }}
            </div>
            <div class="paypal-tip-subtitle f-inter">
                {{ t.tipSubtitle }}
            </div>
        </div>
        <div class="paypal-tip-content">
            <CurrencyInput
                v-model:price="formData.price"
                v-model:currency="formData.currency"
                :currency="currency"
                :currencies="availableCurrencies"
                :price-tooltip="t.tipAmountPlaceholder"
                :reversed-order="true"
                @price:focus="$emit('price:focus', $event)"
                @price:blur="$emit('price:blur', $event)"
                @update:currency="onCurrencyUpdate"
            />
            <div class="paypal-tip-buttons">
                <div class="paypal-button-section">
                    <div
                        v-if="!loading"
                        id="paypal-button-tip"
                    />
                </div>
                <div class="cancel-section">
                    <a
                        class="cancelTip"
                        @click.prevent="$emit('closed')"
                    >
                        {{ t.tipCancel }}
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import CurrencyInput from '@components/Core/CurrencyInput.vue'
import { DEFAULT_CURRENCY, paypalSupportedCurrencies } from '@ts/Util/currency'
import { PropType, computed, reactive, ref, Ref, watch, onMounted } from "vue"
import { PayPalScriptOptions, loadScript, OnInitActions, CreateOrderRequestBody } from "@paypal/paypal-js"
import { PaypalClientID } from "@ts/config"
import { StyleType } from "@components/Payment/PaypalButtons.vue"
import Loading from '@components/Util/Loading.vue'

const props = defineProps({
    t: {
        type: Object,
        default: () => ({
            tipTitle: 'Send tip to',
            tipSubtitle: 'Show some love to your instructor by sending a tip',
            tipAmountPlaceholder: 'Enter tip amount',
            sendTip: 'Send tip',
            tipCancel: 'Cancel',
        }),
    },
    instructorName: {
        type: String,
        required: true,
    },
    instructorEmail: {
        type: String,
        required: true,
    },
    currencies: {
        type: Array as PropType<Array<string>>,
        default: () : Array<string> => paypalSupportedCurrencies
    },
    currency: {
        type: String,
        default: DEFAULT_CURRENCY
    },
    style : {
        type: Object as PropType<StyleType>,
        default: () => {
            return {
                layout: 'vertical',
                color: 'blue',
                shape: 'rect',
                label: 'paypal',
                tagline: false,
            }
        }
    }
})

const loading : Ref<boolean> = ref(true)

const loadPaypalScript = async () => {
    try {
        const scriptOptions: PayPalScriptOptions = {
            'client-id': PaypalClientID,
            'components': 'buttons,marks',
            'enable-funding': 'venmo,card',
            'disable-funding': 'paylater',
            'currency': formData.currency,
        };
        const paypal = await loadScript(scriptOptions)
        return paypal;
    } catch (error) {
        console.error(error)
    } finally {
        loading.value = false;
    }
}

const tipHeader = computed((): String => {
    return `${props.t.tipTitle} ${props.instructorName}`
})

const availableCurrencies = computed(() => {
    return props.currencies.filter(currency => paypalSupportedCurrencies.includes(currency))
})

const formData = reactive({
    price: '',
    currency: props.currency
})

let buttonActions: OnInitActions

const onApprove = (data, actions): Promise<void> => {
    return actions.order.capture().then(function(details) {
        details.paymentSource = data.paymentSource
        emits('paypal:approved', details)
        return Promise.resolve()
    })
}

const createOrder = (data, actions): Promise<string> => {
    emits('paypal:clicked', formData.price, formData.currency)
    return actions.order.create(getOrderData());
}

const onInit = (data, actions): void => {
    buttonActions = actions
    buttonActions.disable()
}
const buttonToggle = (toggle: boolean): void => {
    if (buttonActions) {
        toggle ? buttonActions.enable() : buttonActions.disable()
    }
}

const onCurrencyUpdate = (): void => {
    formData.price = ''
    paypalButtonsRender()
}

const getOrderData = (): CreateOrderRequestBody => {
    return {
        intent: "CAPTURE",
        purchase_units: [
            {
                description: `A tip for instructor ${ props.instructorName }`,
                amount: {
                    currency_code: formData.currency,
                    value: formData.price,
                    breakdown: {
                        item_total: {
                            currency_code: formData.currency,
                            value: formData.price
                        },
                        handling: {
                            currency_code: formData.currency,
                            value: '0'
                        }
                    }
                },
                items: [{
                    name: `A tip for instructor ${ props.instructorName }`,
                    unit_amount: {
                        currency_code: formData.currency,
                        value: formData.price
                    },
                    quantity: '1'
                }],
                payee: {
                    email_address: props.instructorEmail
                }
            }
        ],
        application_context: {
            shipping_preference: "NO_SHIPPING"
        }
    };
}

watch(() => props.currency, (newCurrency) => {
    formData.currency = newCurrency;
})
watch(formData, (newForm) => {
    buttonToggle(Boolean(newForm.price) && Boolean(newForm.currency))
})

const isValid = computed(() => {
    return Boolean(formData.price) && Boolean(formData.currency)
})

const paypalButtonsRender = async () => {
    try {
        const paypal = await loadPaypalScript();
        if (paypal) {
            const buttonOptions = {
                createOrder: createOrder,
                onApprove: onApprove,
                onInit: onInit,
                style: props.style
            }

            const button = paypal?.Buttons?.(buttonOptions)?.render('#paypal-button-tip')
        }
    } catch (error) {
        console.error('Failed to load Paypal JS SDK script', error);
    }
}

onMounted(async () => {
    paypalButtonsRender()
})

const emits = defineEmits([
    'closed',
    'paypal:clicked',
    'paypal:approved',
    'price:focus',
    'price:blur',
])
</script>

<style scoped>
.paypal-tip-body {
    margin: 0;
}
.paypal-tip-title {
    text-align: center;
    margin-bottom: 1rem;
}
.paypal-tip-title-header {
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 1.625rem;
    color: var(--zumba-gray-800);
}
.paypal-tip-subtitle {
    margin-top: 0.5rem;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
    color: var(--zumba-gray-800);
    padding: 0 2rem;
}
.paypal-tip-content {
    padding: 0 1rem;
}
.cancel-section {
    color: var(--zumba-light-pink);
    text-align: center;
    margin-top: 1rem;
    cursor: pointer;
    padding-bottom: .375rem;
}
.paypal-tip-buttons {
    margin-top: 0.8rem;
}
.paypal-button-section {
    position: relative;
    z-index: 1;
}
@media all and (min-width: 48rem) {
    .paypal-tip-body {
        margin: 2rem 1.75rem 1rem;
    }
    .paypal-tip-title-header {
        font-size: 1.5rem;
        line-height: 1.875rem;
    }
    .paypal-tip-subtitle {
        margin-top: 0.3125rem;
        font-size: 1rem;
        line-height: 1.5rem;
        padding: 0;
    }

    .cancel-section {
        padding-bottom: 0;
    }
}
</style>
