<template>
    <div 
        class="class-element f-inter"
        :class="{ 'class-element--redesign': enableRedesign}"
    >
        <div class="class-element__info">
            <div class="class-element__date z-b2">
                <span>{{ classDateText }}</span>
            </div>
            <div class="class-element__time">
                {{ classTimeText }}
                <span v-if="classItem.duration">({{ classDuration }} {{ t.min }})</span>
            </div>
        </div>
        <div class="class-element__details">
            <div
                v-if="classSpecialties.length"
                class="class-element__details-specialties"
            >
                <span
                    class="z-b4"
                >
                    {{ specialtiesAndLevelString }}
                </span>
            </div>
            <a
                class="class-element__title p f-inter"
                :href="classItem.detailsUrl"
            >
                {{ classItem.title }}
            </a>
            <!-- eslint-disable vue/no-v-html-->
            <div
                class="class-element__address z-b4"
                v-html="addressText"
            />
            <!-- eslint-enable vue/no-v-html -->
            <div
                v-if="classItem.platform_type === PLATFORM_TYPES.ZOOM"
                class="z-b4"
            >
                {{ t.zoom }}
            </div>
        </div>
        <div class="class-element__cta">
            <div
                v-if="classItem.platform_type !== PLATFORM_TYPES.IN_PERSON"
                class="class-element__price"
            >
                {{ priceFormatted }}
            </div>
            <div
                v-else-if="classItem.can_accept_payments && classItem.price"
                class="class-element__price"
            >
                {{ priceFormatted }}
            </div>
            <div class="class-element__cta-elements">
                <ZButton
                    class="class-element__book-button"
                    :disabled="classItem.sold_out"
                    :variety="buttonThemed"
                    @click="handleBookClass"
                >
                    {{ ctaText }}
                </ZButton>
                <div
                    v-if="!classItem.can_accept_payments && classItem.location_requires_membership"
                    class="class-element__cta-extras f-inter z-b5"
                >
                    {{ t.locationRequiresMembership }}
                </div>
                <div
                    v-if="!classItem.can_accept_payments && classItem.free_passes_available"
                    class="class-element__cta-extras f-inter z-b5"
                >
                    {{ t.freePassAvailable }}
                </div>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
import { computed, ComputedRef, PropType, ref } from 'vue'
import { formatCurrencyStr } from "@ts/Util/currency";
import { currentUserLocale } from '@ts/Util/i18n'
import ZButton from "@components/Core/ZButton.vue"
import {theme, ThemeType} from "@ts/Util/theme";

export interface ClassData {
    id: string,
    title: string,
    effective_date_utc: string,
    effective_date?: string,
    url: string,
    detailsUrl: string,
    buyUrl: string,
    price?: number,
    duration: string,
    price_currency?: string,
    platform_type?: string,
    address?: string,
    fitness_specialties: string[],
    can_accept_payments?: boolean,
    sold_out: boolean,
    location_requires_membership?: boolean,
    free_passes_available?: boolean
    effective_date_timezone?: string,
    workout_level?: string
}

const PLATFORM_TYPES = {
    VOD: 'VOD',
    ZOOM: 'Zoom',
    IN_PERSON: 'In Person',
}

const props = defineProps({
    specialties: {
        type: Object,
        default: () => {}
    },
    classItem : {
        type: Object as PropType<ClassData>,
        required: true
    },
    enableRedesign: {
        type: Boolean,
        default: false
    },
    t: {
        type: Object,
        default: () => ({
            min: 'min',
            register: 'Register',
            soldOut: 'Sold Out',
            details: 'Details',
            locationRequiresMembership: 'Location requires Membership.',
            freePassAvailable: 'Free pass available.',
            zoom: 'Zoom',
            lowIntensity: 'Low Intensity',
            mediumIntensity: 'Medium Intensity',
            highIntensity: 'High Intensity'
        })
    },
})

const priceFormatted : ComputedRef<string> = computed((): string => {
    return formatCurrencyStr(props.classItem.price || 0, props.classItem.price_currency, currentUserLocale(), true)
})

const classSpecialties: ComputedRef<string[]> = computed((): string[] => {
    const classSpecialties: string[] = [];

    props.classItem.fitness_specialties.forEach((speciality) => {
        if (props.specialties[speciality]) classSpecialties.push(props.specialties[speciality]);
    });

    return classSpecialties;
});

const classDuration: ComputedRef<number> = computed(() => {
    return Math.floor(parseInt(props.classItem.duration) / 60);
})

const classDateText = computed(
    (): string => {
        const dateFormatOpts: any = { weekday: 'long', month: 'short', day: 'numeric'}
        const classDate = (props.classItem.platform_type === PLATFORM_TYPES.IN_PERSON && props.classItem.effective_date) ?
            new Date(props.classItem.effective_date) : new Date(props.classItem.effective_date_utc)

        let classDateTimeFormatted = ""

        try {
            classDateTimeFormatted =  new Intl.DateTimeFormat(currentUserLocale(), dateFormatOpts).format(classDate)
        } catch (error) {
            //safari 15 support: https://github.com/w3c/respec/issues/1357
            const classDate = (props.classItem.platform_type === PLATFORM_TYPES.IN_PERSON && props.classItem.effective_date) ?
                new Date(props.classItem.effective_date.replace(/-/g, "/")) : new Date(props.classItem.effective_date_utc)
            classDateTimeFormatted =  new Intl.DateTimeFormat(currentUserLocale(), dateFormatOpts).format(classDate)
        }
        return classDateTimeFormatted
    }
)

const classTimeText = computed(
    (): string => {
        const timeFormatOpts: any = { hour: 'numeric', minute: '2-digit' }
        const classDate = (props.classItem.platform_type === PLATFORM_TYPES.IN_PERSON && props.classItem.effective_date) ?
            new Date(props.classItem.effective_date) : new Date(props.classItem.effective_date_utc)

        let classDateTimeFormatted = ""

        try {
            classDateTimeFormatted =  new Intl.DateTimeFormat(currentUserLocale(), timeFormatOpts).format(classDate)
        } catch (e) {
            //safari 15 support: https://github.com/w3c/respec/issues/1357
            const classDate = (props.classItem.platform_type === PLATFORM_TYPES.IN_PERSON && props.classItem.effective_date) ?
                new Date(props.classItem.effective_date.replace(/-/g, "/")) : new Date(props.classItem.effective_date_utc)
            classDateTimeFormatted =  new Intl.DateTimeFormat(currentUserLocale(), timeFormatOpts).format(classDate)
        }
        return classDateTimeFormatted
    }
)

const emits = defineEmits(['no-direct-payment-modal:open'])

const ctaText : ComputedRef<string> = computed((): string => {
    if (!props.classItem?.location_requires_membership || props.classItem.platform_type !== PLATFORM_TYPES.IN_PERSON) {
        return (!props.classItem.sold_out) ? props.t.register : props.t.soldOut
    }

    return props.t.details;
})


const handleBookClass = () => {
    if (!!props.classItem?.can_accept_payments || props.classItem.platform_type !== PLATFORM_TYPES.IN_PERSON) {
        window.location.href = `${props.classItem.buyUrl}?potential_source=${encodeURIComponent('Instructor Page')}`
    } else {
        emits('no-direct-payment-modal:open', props.classItem.id)
    }
}

const formattedAddress = computed((): string => {
    const address = props.classItem?.address ?? '';
    const formattedAddressesParts = [...new Set(address.split(', '))];
    return formattedAddressesParts.join(', ');
})

const addressText = computed((): string => {
    return formattedAddress.value ?? props.classItem.platform_type
});

const buttonThemed = theme.value === ThemeType.Zumba ? 'rogue' : 'love';

const intensityString = computed(() => {
    switch (props.classItem.workout_level) {
    case 'low':
        return props.t.lowIntensity
    case 'medium':
        return props.t.mediumIntensity
    case 'high':
        return props.t.highIntensity
    default:
        return ''
    }
})

const specialtiesAndLevelString = computed(() => {
    const fitnessDescription: string[] = [];

    if (classSpecialties.value.length > 0) {
        fitnessDescription.push(classSpecialties.value.join(' / '));
    }

    if (intensityString.value !== '') {
        fitnessDescription.push(intensityString.value);
    }

    return fitnessDescription.join(' • ');
});

</script>
<style scoped>
  .class-element {
    position: relative;
    padding: .75rem;
    border-bottom-left-radius: .25rem;
    border-bottom-right-radius: .25rem;
    border: 2px solid var(--zumba-gray-100);
  }

  .class-element--redesign {
    background: var(--zumba-white);
    border: 1px solid var(--zumba-gray-200);
    border-radius: .25rem;
  }

  .class-element + .class-element {
    margin-top: 1rem;
  }

  .class-element__info {
    display: flex;
    position: relative;
  }

  .class-element__date {
    color: var(--zumba-gray-800);
    font-size: .875rem;
    font-weight: 700;
  }
  .class-element__date span {
    background: var(--zumba-white);
    padding-right: 0.5rem;
    position: relative;
    z-index: 2;
  }

  .class-element__time {
    color: var(--zumba-gray-800);
    font-size: .875rem;
    font-weight: 700;
    line-height: 1.625rem;
  }

  .class-element__time::before {
    content: "•";
    margin-right: 0.45rem;
  }

  .class-element__details {
    min-width: 0;
    color: var(--zumba-gray-600);
    padding-right: 2rem;
  }

  .class-element__details-specialties  span {
    display: inline-block;
    vertical-align: middle;
  }

  .class-element__details-specialties  span + span::before {
    background: var(--zumba-gray-700);
    border-radius: 100%;
    content: " ";
    display: inline-block;
    height: 3px;
    margin: 0 0.5rem;
    vertical-align: middle;
    width: 3px;
  }

  .class-element__title {
    display: block;
    margin: 0.25rem 0;
  }

  .theme-strong .class-element__title {
    color: var(--strong-razzmatazz);
  }

  .theme-zumba .class-element__title {
    color: var(--zumba-plum);
  }

  .class-element__address {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .class-element__price {
    color: var(--zumba-gray-800);
    font-size: 0.875rem;
    font-weight: 700;
    position: absolute;
    right: 0;
    top: 1.25rem;
    padding-right: 1rem;
  }

  .class-element__book-button {
    margin-top: 1rem;
  }

  .class-element__book-button:deep(.z-button) {
    font-weight: 400;
  }

  .class-element__cta-extras {
    color: var(--zumba-gray-500);
    margin-top: 0.25rem;
    text-align: center;
  }
  .theme-strong .class-element__cta-extras {
    font-family: var(--strong-font-default);
    font-size: 0.75rem;
  }

  @media only screen and (min-width: 48rem) {
    .class-element {
      border: 1px solid var(--zumba-gray-200);
      border-radius: 2px;
      display: grid;
      grid-template-columns: 1fr 2fr 1fr;
      padding: 1.5rem 1rem 1.5rem 1.75rem;
    }

    .class-element--redesign {
        border-radius: .25rem;
    }

    .class-element__details {
      border-left: 1px solid var(--zumba-gray-200);
      border-right: 1px solid var(--zumba-gray-200);
      padding: 0 1.5rem;
    }

    .class-element__info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative
    }

    .class-element__date {
        font-size: 1.125rem;
        margin-bottom: 0.5rem;
    }

    .class-element__time {
      color: var(--zumba-gray-600);
      font-weight: 400;
      font-size: .875rem;
      line-height: 1.5rem;
    }

    .class-element__time::before {
        display: none;
    }

    .class-element__price {
      font-size: 1rem;
      position: relative;
      right: initial;
      top: initial;
      padding-right: 0;
    }

    .class-element__cta {
      align-items: center;
      display: flex;
      justify-content: flex-end;
      margin-left:  1rem;
    }

    .class-element__cta-elements {
      margin-left: 1.5rem;
      max-width: 9.413rem;
    }

    .class-element__book-button {
      margin-top: 0;
    }
  }

  @media screen and (max-width: 48em) {
    .class-element__info {
        align-items: center;
    }
  }
</style>
