<template>
    <div class="rating">
        <StarFilledSvg
            v-for="n in filled"
            :key="`filled-${n}`"
            class="rating-star"
        />
        <StarSvg
            v-for="n in empty"
            :key="`empty-${n}`"
            class="rating-star"
        />
    </div>
</template>
<script setup lang="ts">
import { ComputedRef, computed } from 'vue'
import StarSvg from '@bx-icons/regular/bx-star.svg'
import StarFilledSvg from '@bx-icons/solid/bxs-star.svg'

const props = defineProps({
    score : {
        type: Number,
        required: true
    },
    maxScore: {
        type: Number,
        default: 5
    }
})

const filled: ComputedRef<number> = computed(() => {
    return Math.floor(props.score);
});

const empty: ComputedRef<number> = computed(() => {
    return props.maxScore - filled.value;
});
</script>
<style scoped>
  .rating {
    margin-bottom: 0.5rem;
  }

  .rating-star {
    width: 1rem;
  }
</style>
