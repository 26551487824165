<template>
    <div
        class="social-links"
        :class="classes"
    >
        <a
            v-if="parsedSocialLinks.facebook"
            class="social-link"
            :href="parsedSocialLinks.facebook"
            target="_blank"
            rel="noopener"
        >
            <FacebookIcon class="social-icon facebook-icon" />
        </a>
        <a
            v-if="parsedSocialLinks.linkedin"
            class="social-link"
            :href="parsedSocialLinks.linkedin"
            target="_blank"
            rel="noopener"
        >
            <LinkedinIcon class="social-icon linkedin-icon" />
        </a>
        <a
            v-if="parsedSocialLinks.instagram"
            class="social-link"
            :href="parsedSocialLinks.instagram"
            target="_blank"
            rel="noopener"
        >
            <InstagramIcon class="social-icon instagram-icon" />
        </a>
        <a
            v-if="parsedSocialLinks.tiktok"
            class="social-link"
            :href="parsedSocialLinks.tiktok"
            target="_blank"
            rel="noopener"
        >
            <TiktokIcon class="social-icon tiktok-icon" />
        </a>
        <a
            v-if="parsedSocialLinks.spotify"
            class="social-link"
            :href="parsedSocialLinks.spotify"
            target="_blank"
            rel="noopener"
        >
            <SpotifyIcon class="social-icon spotify-icon" />
        </a>
    </div>
</template>
<script lang="ts">
import FacebookIcon from '@bx-icons/logos/bxl-facebook-circle.svg'
import LinkedinIcon from '@bx-icons/logos/bxl-linkedin.svg'
import InstagramIcon from '@bx-icons/logos/bxl-instagram-alt.svg'
import TiktokIcon from '@bx-icons/logos/bxl-tiktok.svg'
import SpotifyIcon from '@bx-icons/logos/bxl-spotify.svg'
import { computed, defineComponent, PropType, Ref, ref } from 'vue'

const localSocialLinks = {
    facebook: '',
    linkedin: '',
    instagram: '',
    tiktok: '',
    spotify: ''
}
export type SocialLinksType = typeof localSocialLinks

export type SocialLinksSize = 'normal' | 'mobile-large'

export default defineComponent({
    name: 'SocialLinks',
    components: {
        FacebookIcon,
        LinkedinIcon,
        InstagramIcon,
        TiktokIcon,
        SpotifyIcon
    },
    props: {
        socialLinks: {
            type: Object as PropType<SocialLinksType>,
            default: () => localSocialLinks,
            validator: (supplied: Object) => Object.keys(localSocialLinks).every(key => key in supplied)
        },
        size: {
            type: String as PropType<SocialLinksSize>,
            default: 'normal',
        }
    },
    setup(props, ctx) {

        let parsedSocialLinks = ref(props.socialLinks).value;

        const socialBaseUrls = {
            facebook: 'facebook.com',
            instagram: 'instagram.com',
            spotify: 'open.spotify.com/user',
            tiktok: 'tiktok.com',
            linkedin: 'linkedin.com/in',
        };

        for (const key in socialBaseUrls) {
            if (parsedSocialLinks.hasOwnProperty(key) && parsedSocialLinks[key].length) {
                // Removing: leading slashes AND any double slashes except ://
                parsedSocialLinks[key] = parsedSocialLinks[key].replace(/^\/+/, '').replace(/:\/+/gm, '://');
                let socialLink = parsedSocialLinks[key].replace(/^https?:\/\//, '');
                if (!socialLink.includes(socialBaseUrls[key])) {
                    socialLink = `${socialBaseUrls[key]}/${parsedSocialLinks[key]}`;
                }
                parsedSocialLinks[key] = `https://${socialLink}`;
            }
        }

        const classes = computed(() => {
            return {
                'social-link--mobile-large': props.size === 'mobile-large'
            }
        })
        return {
            parsedSocialLinks,
            classes,
        }
    },
})

</script>
<style scoped>

a.social-link svg {
    width: 1.5rem;
    height: 1.5rem;
}

.social-links {
    margin-top: .575rem;
}
.social-links > a.social-link:first-child {
    margin-left: 0;
}

.social-links > a:last-child {
    margin-right: 0;
}

.social-links > a.social-link {
    margin-left: 1rem;
    margin-right: 1rem;
}

@media all and (min-width: 65rem) {
    .social-links > a.social-link {
        margin-left: .375rem;
        margin-right: .375rem;
    }

    a.social-link svg {
        width: 1.25rem;
        height: 1.25rem;
    }
}

@media all and (max-width: 65rem) {
    .social-link--mobile-large a.social-link svg {
        width: 2.25rem;
        height: 2.25rem;
    }
}

</style>
