<template>
    <div class="review f-inter">
        <div class="review__student-avatar">
            <img
                v-if="review.profile_photo_url"
                :src="review.profile_photo_url + '?w=40&h=40&fit=crop&auto=format,compress 2x'"
            >
            <div
                v-else
                class="default-student-avatar"
            >
                <span class="student-initials">
                    {{ studentInitials }}
                </span>
            </div>
        </div>
        <div class="review__content">
            <div class="review__student-name z-b2">
                {{ review.display_name }}
            </div>
            <div class="review__date z-b4">
                {{ reviewDateText }} {{ t.at }} {{ reviewTimeText }}
            </div>
            <Rating
                :score="review.rating"
            />
            <div
                v-if="review.class_title"
                class="review__class-name z-b3"
            >
                {{ review.class_title }}
            </div>
            <div
                v-if="review.comment"
                class="review__details z-b2"
            >
                {{ review.comment }}
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
import { PropType, Ref, computed, ref } from 'vue'
import Rating from './Rating';
import { currentUserLocale } from "@ts/Util/i18n";

export interface ReviewData {
    pid: string,
    display_name: string,
    first_name: string,
    last_name: string,
    profile_photo_url?: string,
    class_title?: string,
    rating?: number,
    comment?: string,
    created: string,
}

const props = defineProps({
    review : {
        type: Object as PropType<ReviewData>,
        required: true
    },
    t: {
        type: Object,
        default: () => ({
            at: 'at',
        })
    }
})

const reviewDateText: Ref<string> = computed(
    (): string => {
        const dateFormatOpts: any = { weekday: 'short', month: 'short', day: 'numeric', year: 'numeric'}
        return new Intl.DateTimeFormat(currentUserLocale(), dateFormatOpts).format(new Date(props.review.created));
    }
)

const reviewTimeText: Ref<string> = computed(
    (): string => {
        const timeFormatOpts: any = { hour: 'numeric', minute: '2-digit' }
        return new Intl.DateTimeFormat(currentUserLocale(), timeFormatOpts).format(new Date(props.review.created));
    }
)
const firstName = ref(props.review.first_name)
const lastName = ref(props.review.last_name)

const studentInitials: Ref<string> = computed(() => {
    const initials = `${firstName.value.charAt(0)}${lastName.value.charAt(0)}`
    return initials.toUpperCase()
})
</script>
<style scoped>
.review {
  display: flex;
}

.review__student-avatar {
  margin-right: 1rem;
  width: 2.5rem;
}

.review__student-avatar img {
  border-radius: 100%;
}

.default-student-avatar {
  width: 2.5rem;
  height: 2.5rem;
  background: var(--zumba-gray-200);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.student-initials {
  color: var(--zumba-gray-400);
  font-weight: bold;
}

.review__content {
  flex-grow: 1;
  max-width: calc(100% - 3.5rem);
}

.review__student-name {
  font-weight: 700;
  line-height: 1.5rem;
}

.review__date {
  color: var(--zumba-gray-400);
  line-height: 1.25rem;
  margin-bottom: 1rem;
}

.review__class-name {
  font-weight: 700;
  line-height: 1.25rem;
  margin-bottom: 0.5rem;
}

.review__details {
  font-size: 1rem;
  line-height: 1.5rem;
}

</style>
