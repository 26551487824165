<template>
    <div class="item-container">
        <div class="item-heading">
            <p class="z-b2 f-inter item-title">
                {{ data.class_quantity }} {{ t.classes }}
            </p>
            <p class="z-b4 f-inter item-subtitle">
                {{ subtitle }}
            </p>
        </div>
        <div class="item">
            <p
                v-if="data.description"
                class="z-b4 f-inter"
            >
                {{ data.description }}
            </p>
            <div class="item-content">
                <p
                    class="z-b5 f-inter"
                >
                    {{ t.applies }}
                    <span
                        v-for="(contentType, index) in data.content_types"
                        :key="contentType"
                        class="z-b5 f-inter"
                    >
                        {{ contentType.title }}
                        <span v-if="index < data.content_types.length - 1">, </span>
                        <span v-if="index === data.content_types.length - 2"> {{ and }} </span>
                    </span>
                </p>
            </div>
            <div class="item-types-wrapper">
                <div
                    v-if="allItemsDisplayed"
                    ref="itemTypesContainerRef"
                    class="item-types-container"
                    :class="{expanded:showMore}"
                >
                    <ul class="item-lists">
                        <li
                            v-for="specialty in filteredSpecialties"
                            :key="specialty.slug"
                            class="z-b4 f-inter item-type"
                        >
                            <b>{{ specialty.name }}</b>
                        </li>
                    </ul>
                </div>
                <div v-else>
                    <ul class="item-lists">
                        <li class="z-b4 f-inter item-type">
                            <b class="item-type">
                                {{ t.allFitnessTypes }}
                            </b>
                        </li>
                    </ul>
                </div>
            </div>
            <div
                v-if="showMoreLink"
                class="item-types-show-more-link"
            >
                <a
                    href="#"
                    class="z-b4 f-inter"
                    @click.prevent="showMore = !showMore"
                >
                    {{ showMore ? t.seeLess : t.seeAll }}
                </a>
            </div>
            <div class="item-cta">
                <ZButton
                    variety="rogue"
                    :href="data.url"
                    wide="never"
                >
                    {{ t.buy }}
                </ZButton>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref, computed, onMounted } from 'vue';
import ZButton from '@components/Core/ZButton.vue';
import {validateTranslations} from "@ts/Util/i18n";

const translationDefaults = {
    buy: "Buy",
    classes: 'Classes',
    applies: 'Applies to',
    allFitnessTypes: 'All Fitness Types',
    seeAll: 'See all class types',
    seeLess: 'See less class types'
}

export interface ClassPacksOptionData  {
    id: string;
    description: string;
    price: number;
    price_currency: string;
    class_quantity: number;
    content_types: {
        title:string
    }
    fitness_specialties: Array<{ slug: string, name: string }>;
    url: string,
}

export default defineComponent({
    name: 'ClassPacksItem',
    components: {
        ZButton
    },
    props: {
        t: {
            type: Object,
            default: () : Object => translationDefaults,
            validator: (value : Object) => validateTranslations(value, translationDefaults)
        },
        data: {
            type: Object as PropType<ClassPacksOptionData>,
            required: true
        },

        subtitle: {
            type: String,
            default: ''
        },
        maxDisplayed: {
            type: Number,
            default: 19
        },
        and: {
            type: String,
            default: 'and',
        }
    },
    setup(props) {
        const itemTypesContainerRef = ref(null)
        const showMoreLink = ref(false)
        const showMore = ref(false)

        const filteredSpecialties = computed(() => {
            const uniqueCombinations: { slug: string; name: string; }[] = [];
            props.data.fitness_specialties.forEach((item) => {
                const { slug, name } = item;
                if (!uniqueCombinations.find(
                    (combination) =>
                        combination.slug === slug &&
                        combination.name === name
                )
                ) {
                    uniqueCombinations.push({ slug, name });
                }
            });

            return uniqueCombinations;
        });

        const allItemsDisplayed = computed(() => {
            return props.maxDisplayed >= props.data.fitness_specialties.length;
        });

        onMounted(() => {
            if (itemTypesContainerRef.value) {
                const scrollHeight = itemTypesContainerRef.value.scrollHeight
                const clientHeight = itemTypesContainerRef.value.clientHeight
                if (scrollHeight > clientHeight) {
                    showMoreLink.value = true;
                }
            }
        })

        return {
            itemTypesContainerRef,
            showMoreLink,
            showMore,
            allItemsDisplayed,
            filteredSpecialties
        };
    }
});

</script>

<style scoped>
.item-container {
    max-width: 20rem;
    border: 0.0625rem solid var(--zumba-gray-200);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    text-align: center;
    background-color: var(--zumba-white);
}
.item {
    padding: 1rem 1.5em 1.5rem;
    text-align: center;

    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    flex-grow: 1;
}
.item-heading {
    border-radius: var(--radius-4) var(--radius-4) 0 0;
    padding: 0.8rem 1rem 0.75rem;
    background-color: var(--zumba-light-purple);
    text-align: center;
    color: var( --zumba-white);
}

.theme-strong .item-heading {
    background-color: var(--zumba-gray-800);
}

.item-title {
    font-weight: bold;
    margin-bottom: 0;
}
.item-subtitle  {
    margin-bottom: 0;
}
.item-content {
    height: 2.25rem;
}
.item-content .z-b5 {
    margin: 0;
}
.item-types-wrapper {
    display: flex;
    justify-content: center;
    flex-grow: 2;
    padding: 1rem 0;
}

.item-types-container {
    overflow: hidden;
    max-height: 5.25rem;
}

.item-types-container.expanded {
    max-height: unset;
}

.item-types-show-more-link {
    margin: -1rem auto 1.5rem;
}

.theme-strong .item-content {
    height: auto;
}

.item-lists {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 0.438rem 0.5rem;
    gap: 0.5rem;
    list-style: none;
    margin-left: 0;
}
.item-type {
    background-color: var(--zumba-gray-100);
    padding: 0.375rem;
    border-radius: 0.25rem;
}
.item-cta {
    display: inline-block;
}

.theme-strong .item-cta:deep(a) {
    background-color: unset;
    color: var(--strong-razzmatazz);
    border: 1px solid var(--strong-razzmatazz);
}

.theme-strong .item-cta:deep(a:hover) {
    background-color: var(--strong-razzmatazz);
    color: var(--zumba-white);
}

@media only screen and (min-width: 29rem) {
    .item-container {
        width: 19.5rem;
    }
}

</style>
