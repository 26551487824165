<template>
    <section
        v-if="editMode || classes?.length"
        class="instructor-section"
    >
        <div class="instructor-section__header">
            <h5 class="instructor-section__title f-inter">
                {{ t.title }}
            </h5>
            <a
                v-if="editMode"
                :href="liveClassesAction"
                class="edit-icon"
            >
                <EditIcon />
            </a>
            <div
                v-if="(!editMode || classes?.length) && !hideFilters"
                class="instructor-section__filters"
            >
                <span
                    class="class-list__filter-trigger"
                >
                    <div
                        v-if="dateFilter"
                        class="class-list__filter-count z-b5"
                    >
                        1
                    </div>
                    <DatePicker
                        v-model="dateFilter"
                        class="class-list__filter-datepicker"
                    >
                        <template #icon>
                            <CalendarIcon class="class-list__filter-icon" />
                        </template>
                    </DatePicker>
                </span>
                <a
                    class="class-list__filter-trigger"
                    @click.prevent="showFilters = true"
                >
                    <div
                        v-if="filterCount > 0"
                        class="class-list__filter-count z-b5"
                    >
                        {{ filterCount }}
                    </div>
                    <FilterIcon class="class-list__filter-icon" />
                </a>
            </div>
        </div>
        <div
            v-if="visibleClasses?.length"
        >
            <div class="class-list">
                <ClassElement
                    v-for="classItem in visibleClasses"
                    :key="classItem.id"
                    :class-item="classItem"
                    :specialties="specialties"
                    :t="t"
                    @no-direct-payment-modal:open="$emit('no-direct-payment-modal:open', $event)"
                />
            </div>
        </div>
        <div
            v-else-if="classes?.length"
        >
            <div class="no-classes-posted f-inter">
                {{ t.noClassesAvailable }}
                <a
                    class="z-b3 f-inter"
                    @click.prevent="clearFilters()"
                >
                    {{ t.viewFullSchedule }}
                </a>
            </div>
        </div>
        <div
            v-else
        >
            <div class="no-classes-posted f-inter">
                {{ t.noClassesPosted }}
                <a
                    class="z-b3 f-inter"
                    :href="liveClassesAction"
                >
                    {{ t.add }}
                </a>
            </div>
        </div>
        <div
            v-if="filteredClasses.length > visibleRecords"
            class="class-list__show-more"
        >
            <a
                class="f-inter"
                @click.prevent="visibleRecords += pageSize"
            >
                {{ t.showMore }}
            </a>
        </div>

        <ClassFilterModal
            :t="t"
            :show="showFilters"
            :specialties="specialties"
            :available-filters="availableFilters"
            @apply="(filters) => selectedFilters = filters"
            @closed="showFilters = false"
        />
    </section>
</template>

<script lang="ts">
import { defineComponent, ComputedRef, PropType, Ref, computed, ref } from 'vue';
import { validateTranslations } from '@ts/Util/i18n'
import CalendarIcon from '@bx-icons/regular/bx-calendar-alt.svg';
import FilterIcon from '@bx-icons/regular/bx-filter.svg';
import EditIcon from '@bx-icons/regular/bx-pencil.svg';
import { ClassData, default as ClassElement } from '@components/ClassListing/ClassElement.vue';
import ClassFilterModal from '@components/ClassListing/ClassFilterModal.vue';
import DatePicker from "@components/ClassListing/DatePicker.vue";

interface Filters {
    durations:  string[];
    specialties:  string[];
    locations:  string[];
}

const translationDefaults = {
    title: 'Live Classes',
    showMore: 'Show More',
    noClassesPosted: 'No classes posted.',
    add: 'add',
    noClassesAvailable: 'There are no classes available',
    viewFullSchedule: 'Click here to view full schedule'
}

export default defineComponent({
    name: 'LiveClasses',
    components: {
        CalendarIcon,
        FilterIcon,
        EditIcon,
        ClassElement,
        ClassFilterModal,
        DatePicker
    },
    props: {
        classes: {
            type: Array as PropType<ClassData[]>,
            required: true,
        },
        specialties: {
            type: Object,
            default: () => {}
        },
        editMode: {
            type: Boolean,
            default: () => false
        },
        liveClassesAction: {
            type: String,
            default: ''
        },
        hideFilters: {
            type: Boolean,
            default: false
        },
        pageSize: {
            type: Number,
            default: 5
        },
        t: {
            type: Object,
            required: true,
            validator: (value : Object) => validateTranslations(value, translationDefaults)
        }
    },
    emits: [
        'no-direct-payment-modal:open'
    ],
    setup(props) {
        const visibleRecords: Ref<number> = ref(props.pageSize)
        const showFilters: Ref<boolean> = ref(false)
        const dateFilter: Ref<String> = ref('');
        const selectedFilters: Ref<Filters> = ref({
            durations: [],
            specialties: [],
            locations: [],
        });

        let durationsFilter: string[] = [], specialtiesFilter: string[] = [], locationsFilter: string[] = [];
        props.classes.forEach((classItem) => {
            let duration;
            const location = classItem.platform_type === 'In Person' ?  'inPerson' : 'virtual';
            specialtiesFilter = specialtiesFilter.concat(classItem.fitness_specialties);
            let classDuration = parseInt(classItem.duration);

            if (classDuration < 1860) {
                duration = 'underThirty';
            } else if (classDuration <= 3600) {
                duration = 'thirtySixty';
            } else {
                duration = 'overSixty';
            }

            if (!durationsFilter.includes(duration)) {
                durationsFilter.push(duration);
            }

            if (!locationsFilter.includes(location)) {
                locationsFilter.push(location);
            }
        });

        const availableFilters: Ref<Filters> = ref({
            durations: durationsFilter,
            specialties: [...new Set(specialtiesFilter)],
            locations: locationsFilter
        });

        const filteredClasses: ComputedRef<ClassData[]> = computed(() => {
            let { durations, specialties, locations } = selectedFilters.value;

            if (!props.classes) {
                return [];
            }

            return props.classes.filter((classItem) => {

                if (dateFilter.value) {
                    const dateFormatOpts: any = { year: 'numeric', month: 'numeric', day: 'numeric'};
                    const classDate = new Intl.DateTimeFormat('en-US', dateFormatOpts).format(new Date(classItem.effective_date_utc));
                    const filterDate = new Intl.DateTimeFormat('en-US', dateFormatOpts).format(new Date(dateFilter.value + 'T00:00:00'));

                    if (filterDate !== classDate) return false;
                }

                if (durations.length &&
                    durations.every((d: any) => d.min > classItem.duration || d.max < classItem.duration)
                ) {
                    return false
                }

                if (
                    specialties.length &&
                    classItem.fitness_specialties.every(s => !specialties.includes(s))
                ) {
                    return false
                }

                if (locations.length) {
                    const isInPerson: boolean = classItem.platform_type === 'In Person'
                    const isVirtual: boolean = classItem.platform_type === 'Zoom'

                    if (isVirtual && !locations.includes('virtual')) {
                        return false
                    }
                    if (isInPerson && !locations.includes('inPerson')) {
                        return false
                    }
                }

                return true;
            });
        });

        const filterCount: ComputedRef<number> = computed(() => {
            let { durations, specialties, locations } = selectedFilters.value;
            return durations.length + specialties.length + locations.length;
        })

        const visibleClasses: ComputedRef<ClassData[]> = computed(() => {
            return filteredClasses.value.slice(0, visibleRecords.value);
        });

        const clearFilters = () => {
            dateFilter.value = '';
            selectedFilters.value = {
                durations: [],
                specialties: [],
                locations: [],
            };
        };

        return {
            visibleRecords,
            showFilters,
            dateFilter,
            selectedFilters,
            availableFilters,
            filteredClasses,
            visibleClasses,
            filterCount,
            clearFilters
        }
    }
});
</script>
<style scoped>
    .instructor-section__title {
        margin: 0;
    }

    .theme-strong .instructor-section__title {
        font-family: var(--strong-font-default);
    }

    .instructor-section__header {
        display: flex;
        align-items: center;
    }

    .instructor-section__filters {
        display: flex;
        margin-left: auto;
    }

    .class-list {
        margin-top: 1.5rem;
    }

    .class-list__show-more {
        margin-top: 1.5rem;
        text-align: center;
    }

    .class-list__show-more a {
        color: var(--zumba-plum);
        display: block;
    }

    .theme-strong .class-list__show-more a {
        color: var(--strong-razzmatazz);
    }

    .theme-zumba .class-list__show-more a {
        color: var(--zumba-plum);
    }

    .class-list__filter-trigger {
        border-radius: .125rem;
        border: 1px solid;
        height: 2.5rem;
        margin-left: 0.5rem;
        padding: 0.625rem;
        position: relative;
        width: 2.5rem;
    }

    .theme-strong .class-list__filter-trigger {
        border-color: var(--strong-razzmatazz);
    }

    .theme-zumba .class-list__filter-trigger {
        border-color: var(--zumba-plum);
    }

    .class-list__filter-datepicker {
        left: 0;
        position: absolute;
        top: 0;
    }

    .class-list__filter-count {
        background: var(--zumba-plum);
        border-radius: 100%;
        color: var(--zumba-white);
        display: block;
        font-weight: 500;
        height: 1rem;
        line-height: 1rem;
        position: absolute;
        right: -0.5rem;
        text-align: center;
        top: -0.5rem;
        width: 1rem;
    }

    .theme-strong .class-list__filter-count {
        background: var(--strong-razzmatazz);
    }

    .theme-zumba .class-list__filter-count {
        background: var(--zumba-plum);
    }

    .class-list__filter-icon {
        display: block;
        height: 1.25rem;
        width: 1.25rem;
    }

    .theme-strong .class-list__filter-icon {
        fill: var(--strong-razzmatazz);
    }

    .theme-zumba .class-list__filter-icon {
        fill: var(--zumba-plum);
    }

    .no-classes-posted {
        text-align: center;
        margin: 1.5em 0;
    }
    .edit-icon {
        align-items: center;
        display: flex;
        margin: -0.2rem 0 0 0.5rem;
    }

    .edit-icon svg {
        width: 1.125rem;
        height: 1.125rem;
    }
</style>
