<template>
    <div
        class="profile-image-container"
        :class="[backgroundClass, { small }]"
    >
        <img
            v-if="profilePhotoUrl"
            :src="profilePicThumbnail(profilePhotoUrl, 146)"
            :srcset="profilePicThumbnail(profilePhotoUrl, 146) + ' 1x,' +
                profilePicThumbnail(profilePhotoUrl, 292) + ' 2x'"
            :alt="t.profileImageAlt"
            class="profile-image-img"
            :style="{ '--bg-color': themeColor }"
        >
        <DefaultProfilePic
            v-else
            :class="[defaultImageClass, { small }]"
        />
        <div
            v-if="instructorType"
            class="instructor-type"
            :style="{ '--bg-color': themeColor }"
        >
            <span>{{ instructorType }}</span>
        </div>
        <div
            v-if="editMode"
            class="edit-mode"
        >
            <ZButton
                variety="phoenix"
                wide="never"
                rounded="always"
                class="edit-mode-button"
                @click="openUploadModal"
            >
                <template #icon>
                    <CameraSvg class="camera-svg" />
                </template>
            </ZButton>
        </div>
    </div>
    <UploadProfilePictureModal
        v-if="editMode"
        :t="t.modal"
        :show-modal="showModal"
        :handle-closed-modal="() => showModal = false"
        :photo-alt="'Instructor Profile'"
        :photo-url="profilePhotoUrl"
        @update:photo="handleSavedPhoto"
    />
</template>

<script lang="ts">
import { defineComponent, ref, computed } from 'vue'
import {validateTranslations} from "@ts/Util/i18n"
import { profilePicThumbnail } from '@ts/Util/profile_pic';
import DefaultProfilePic from "@images/default-profile-pic.svg"
import ZButton from '@components/Core/ZButton.vue'
import CameraSvg from '@bx-icons/solid/bxs-camera.svg'
import UploadProfilePictureModal from '@modules/InstructorPage/UploadProfilePictureModal.vue'
import { csrfToken } from '@ts/Util/security';

export const BrandColors = {
    'zumba': 'var(--zumba-light-coral)',
    'strong': '#FF0061'
}

export const translationDefaults = {
    profileImageAlt: "Instructor picture",
    modal: {
        instructions: 'Choose a picture that shows off your face, smile, and glowing personality. This photo will show everywhere your classes appear (including Zumba, STRONG, and other platforms).',
        title: 'Instructor Photo',
        addPhoto: 'Add Photo',
        add: 'Add',
        deletePhoto: 'Delete Photo',
        delete: 'Delete',
        save: 'Save',
        cancel: 'Cancel',
        hint: 'We recommend a square 400x400px image, .jpg or .png',
    }
};

export default defineComponent({
    name: 'ProfilePicture',
    components: {
        DefaultProfilePic,
        ZButton,
        CameraSvg,
        UploadProfilePictureModal
    },
    props: {
        t: {
            type: Object,
            required: true,
            validator: (value : Object) => validateTranslations(value, translationDefaults)
        },
        profilePhotoUrl: {
            type: String,
            default: '',
            required: false
        },
        instructorType: {
            type: String,
            required: false,
            default: ''
        },
        editMode: {
            type: Boolean,
            required: false,
            default: false
        },
        savePhotoAction: {
            type: String,
            required: false,
            default: ''
        },
        backgroundClass: {
            type: String,
            required: false,
            default: ''
        },
        themeColor: {
            type: String,
            required: false,
            default: ''
        },
        instructorPID: {
            type: String,
            required: false,
            default: ''
        },
        small: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    emits: [
        'photo:updated'
    ],
    setup(props, context) {
        const showModal = ref(false)
        const openUploadModal = () => showModal.value = true
        const handleSavedPhoto = async (photoPreview) => {
            if (!props.savePhotoAction) {
                throw Error('Invalid URL for saving photo')
            }
            const formData = new FormData();
            const file = typeof photoPreview.file !== 'symbol' ? photoPreview.file : null
            formData.append('photoPreview', file);

            try {
                const request = await fetch(props.savePhotoAction, {
                    method: 'POST',
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'X-CSRF-Token': csrfToken(),
                    },
                    body: formData
                })
                const resp = await request.json()
                if (!request.ok || !resp.success) {
                    throw Error('Error while uploading instructor photo')
                    return
                }
                showModal.value = false
                context.emit('photo:updated', resp.cover_photo)
            } catch (err) {
                console.log(err)
                throw Error('Error while uploading instructor photo')
            }
        }
        const defaultImageClass = computed(() => {
            if (props.themeColor === BrandColors.strong) {
                return 'default-strong'
            }
            return 'default-zumba'
        })

        return {
            showModal,
            openUploadModal,
            profilePicThumbnail,
            handleSavedPhoto,
            defaultImageClass
        }
    }
});


</script>

<style scoped>
.profile-image-img {
    display: block;
    width: 14.5rem;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    z-index: 1;
}

.profile-image-img {
    border: .375rem solid var(--bg-color);
}

.profile-image-container {
    background-size: 17.375rem;
    background-position-x: 0;
    padding-left: 1.4375rem;
    height: 16.875rem;
}

.instructor-type {
    background-color: var(--bg-color);
    position: relative;
    transform: translate(-50%, -50%);
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    top: -1.5625rem;
    left: 11.625rem;
}

.instructor-type span {
    font-size: 1.125rem;
    font-weight: 900;
    color: #ffffff;
    text-align: center;
}

.camera-svg {
    fill: var(--zumba-white);
}

.small .profile-image-img {
    width: 120px;
    height: 120px;
    border: 4px solid var(--bg-color);
}

.small .instructor-type {
    width: 34px;
    height: 34px;
    top: -20px;
    left: 100px;
}

.small .instructor-type span {
    font-size: .8rem;
    font-weight: 900;
    color: #ffffff;
    text-align: center;
}

.small.profile-image-container {
    background-size: 150px;
    background-position-x: 10px;
    background-position-y: 20px;
    padding: 22px;
    width: 164px;
    height: 164px;
}

.small svg.small {
    width: 120px;
    height: 120px;
}

.edit-mode {
    position: relative;
    transform: translate(-50%, -50%);
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    top: -1.5625rem;
    left: 2.625rem;
}

.edit-mode :deep(.z-button) {
    width: 50px;
    height: 50px;
    background-color: var(--zumba-gray-800);
    border-color: transparent;
}

.edit-mode :deep(.rounded-always) {
    padding: 0.5rem;
}
.default-strong {
    transform: translate(30%, 30%);
}

@media only screen and (max-width: 47.9375em) {
    .profile-image-container {
        padding-left: 0;
        background-size: 17.1rem;
    }
    .default-strong {
        transform: translate(0, 0);
    }
}

</style>
