<template>
    <div 
        v-if="enableRedesign"
        class="redesign-margin-top"
    />
    <section
        id="instructor-reviews"
        class="instructor-section"
    >
        <!-- v-show instead of v-if to always be able to show the modal -->
        <div
            class="instructor-section__header"
        >
            <h5 class="instructor-section--title f-inter">
                {{ t.title }}
            </h5>
        </div>
        <div
            v-if="state.reviews.length"
        >
            <div class="review-cta f-inter z-b3">
                <span v-if=" state.ratingScoreFormatted >= 3">
                    <StarFilledSvg /> {{ state.ratingScoreFormatted }}
                    <span>·</span>
                    <strong>{{ state.numRatesFormatted }} {{ t.ratings }} | {{ state.numReviewsFormatted }} {{ t.reviews }}</strong>
                </span>
                <CopyableText
                    v-if="editMode"
                    href="#"
                >
                    <template #default="{ copyTextToClipboard }">
                        <strong>
                            <a
                                href="javascript:void(0)"
                                class="f-inter"
                                @click.prevent="copyTextToClipboard(rateLink)"
                            >
                                {{ t.copyRatingLink }}
                            </a>
                        </strong>
                    </template>
                    <template #copied>
                        {{ t.copied }}
                    </template>
                </CopyableText>
                <a
                    v-else
                    href="javascript:void(0)"
                    class="f-inter z-b4"
                    @click="handleRateNowLink"
                >
                    <b>
                        {{ t.rateNow }}
                    </b>
                </a>
            </div>
            <div
                v-if="state.showSelfRatingError"
                class="review-cta__self-rate-error"
            >
                {{ t.youCannotRateYourself }}
            </div>
            <div
                class="review-list"
            >
                <Review
                    v-for="reviewItem in state.visibleReviews"
                    :key="String(reviewItem.pid) + '-' + String(reviewItem.created)"
                    class="review-list__item"
                    :review="reviewItem"
                    :t="t"
                />
            </div>
            <div
                v-if="state.reviews.length > state.visibleRecords"
                class="review-list__show-more"
            >
                <a
                    class="f-inter"
                    @click.prevent="state.visibleRecords += 6"
                >
                    {{ t.showMore }}
                </a>
            </div>
            <div
                class="review-list-desktop"
            >
                <div class="review-list-column">
                    <Review
                        v-for="reviewItem in state.visibleReviewsSplit[0]"
                        :key="String(reviewItem.pid) + '-' + String(reviewItem.created)"
                        class="review-list__item"
                        :review="reviewItem"
                        :t="t"
                    />
                </div>
                <div class="review-list-column">
                    <Review
                        v-for="reviewItem in state.visibleReviewsSplit[1]"
                        :key="String(reviewItem.pid) + '-' + String(reviewItem.created)"
                        class="review-list__item"
                        :review="reviewItem"
                        :t="t"
                    />
                </div>
            </div>
            <div
                v-if="state.reviews.length > state.visibleRecords"
                class="review-list__show-more-desktop"
            >
                <a
                    class="f-inter"
                    @click.prevent="state.visibleRecords += 6"
                >
                    {{ t.showMore }}
                </a>
            </div>
        </div>
        <div
            v-else-if="editMode && !state.reviews.length"
        >
            <div class="no-reviews z-b3 f-inter">
                {{ t.askForSomeLove }}
                <copyable-text>
                    <template #default="{ copyTextToClipboard }">
                        <a
                            class="f-inter"
                            @click="copyTextToClipboard(rateLink)"
                        >
                            {{ t.copyRatingLink }}
                        </a>
                    </template>
                    <template #copied>
                        {{ t.copied }}
                    </template>
                </copyable-text>
            </div>
        </div>
        <div
            v-else
        >
            <div class="no-reviews z-b3 f-inter other-instructor">
                <div
                    v-if=" state.ratingScoreFormatted >= 3"
                    class="reviews-count"
                >
                    <StarFilledSvg /> {{ state.ratingScoreFormatted }}
                    <span>·</span>
                    <strong>{{ state.numRatesFormatted }} {{ t.ratings }} | {{ state.numReviewsFormatted }} {{ t.reviews }}</strong>
                </div>

                {{ t.beFirstOneShowLove }}

                <a
                    href="#"
                    class="f-inter"
                    @click.prevent="handleRateNowLink"
                >
                    {{ t.rateNow }}
                </a>

                <div
                    v-if="state.showSelfRatingError"
                    class="review-cta__self-rate-error self-rate-spacing"
                >
                    {{ t.youCannotRateYourself }}
                </div>
            </div>
        </div>
        <!-- teleport to escape if section is invisible -->
        <Teleport to="body">
            <RateInstructorHandler
                v-model:show="state.showRating"
                mode="rate"
                :t="t"
                :is-logged-in="isLoggedIn"
                :rating-submitted="instructorRatingSubmitted"
                :rating="studentInstructorRating"
                :rate-link="rateLink"
                :rate-instructor-action="rateInstructorAction"
                :instructor-name="instructorName"
                :instructor-pid="instructorPid"
                @rate-instructor:reviews-updated="updateRatingsAndReviews"
            />
        </Teleport>
    </section>
</template>

<script setup lang="ts">
import {PropType, computed, reactive} from 'vue'
import { ReviewData } from '@components/Reviews/Review.vue'
import StarFilledSvg from '@bx-icons/solid/bxs-star.svg'
import { default as Review } from '@components/Reviews/Review.vue'
import RateInstructorHandler, {RatingAndReviewData} from "@modules/Rating/RateInstructorHandler.vue";
import CopyableText from "@components/Core/CopyableText.vue";
import { currentUserLocale } from "@ts/Util/i18n";

const props = defineProps({
    ratingScore: {
        type: Number,
        required: true,
        validator: (value : number) => value >= 0 && value <= 5,
    },
    showRatingModalInitially: {
        type: Boolean,
        required: true,
    },
    editMode: {
        type: Boolean,
        required: true,
    },
    isOwnProfile: {
        type: Boolean,
        required: true,
    },
    instructorName: {
        type: String,
        required: true,
    },
    isLoggedIn: {
        type: Boolean,
        required: true,
    },
    numReviews: {
        type: Number,
        default: 0,
        validator: (value : number) => value >= 0,
    },
    reviews: {
        type: Array as PropType<ReviewData[]>,
        required: true,
    },
    rateLink: {
        type: String,
        required: true
    },
    instructorRatingSubmitted: {
        type: String,
        required: true
    },
    studentInstructorRating: {
        type: [Number, String],
        required: true,
    },
    instructorPid: {
        type: String,
        required: true,
    },
    rateInstructorAction: {
        type: String,
        required: true,
    },
    enableRedesign: {
        type: Boolean,
        default: false
    },
    t: {
        type: Object,
        default: () => ({
            title: 'Ratings',
            ratings: 'Ratings',
            reviews: 'reviews',
            rateNow: 'Rate Now',
            showMore: 'Show More',
            at: 'at',
            rateYourExperience:'Rate your experience with {0}',
            instructorRating: 'Instructor rating',
            ratingSubmitted:'Rating submitted',
            reviewHeader :'Leave a review',
            reviewBody :'Share a review about this instructor.',
            reviewPlaceHolder: 'How was their class, their coaching style?',
            ratingSubmittedOn :'Rating submitted on {0} at {1}',
            skipForNow :'Skip for now',
            submit :'Submit',
            cancel: 'Cancel',
            copyRatingLink: 'Copy rating link',
            copied: 'Copied!',
            askForSomeLove: 'Ask for some love from your fans.',
            youCannotRateYourself: 'You cannot rate yourself.',
            beFirstOneShowLove: 'This instructor doesn’t have any review to show for now. Be the first to show some love!',
        })
    }
})


const state = reactive({
    ratingScore: props.ratingScore,
    numReviews: props.numReviews,
    reviews: (props.reviews || []).slice(0) as Array<ReviewData>,
    visibleRecords: 6,
    visibleReviews: computed(() => {
        return state.reviews.slice(0, state.visibleRecords)
    }),
    numReviewsFormatted: computed(() => {
        const reviews = (props.reviews || []).filter((rate) => {
            return !!rate.comment;
        });
        return reviews.length;
    }),
    numRatesFormatted: computed(() => {
        return state.numReviews.toString();
    }),
    ratingScoreFormatted: computed(() => {
        const score = Math.round(state.ratingScore * 10) / 10;
        return Intl.NumberFormat(currentUserLocale(), {
            minimumSignificantDigits: 2,
            maximumSignificantDigits: 2,
        }).format(score);
    }),
    showRating: Boolean(
        props.showRatingModalInitially &&
            !props.isOwnProfile &&
            !props.editMode &&
            props.isLoggedIn
    ),
    showSelfRatingError: false,
    visibleReviewsSplit: computed(() => splitReviews())
})

const updateRatingsAndReviews = (ratingData: RatingAndReviewData) => {
    state.reviews = ratingData.reviews
    state.numReviews = ratingData.num_reviews
    state.ratingScore = ratingData.rating_score
}

function tryToShowRatingModal() {
    if (props.isOwnProfile || props.editMode) {
        state.showSelfRatingError = true
    } else {
        state.showRating = true
    }
}

function redirectAndRateLater() {
    window.location.href = '/login?redirect=' + encodeURIComponent(props.rateLink)
}

function handleRateNowLink() {
    if (props.isLoggedIn) {
        tryToShowRatingModal()
        return
    }

    redirectAndRateLater()
}

function splitReviews(): Array<ReviewData[]> {
    const result: Array<ReviewData[]> = []
    const left: ReviewData[] = []
    const right: ReviewData[] = []
    state.visibleReviews.map((record: ReviewData, index: number) => {
        (index + 1) % 2 ? left.push(record) : right.push(record)
    })
    result.push(left)
    result.push(right)
    return result
}

</script>
<style scoped>

.theme-strong .instructor-section--title {
    font-family: var(--strong-font-default);
}

.review-list__item {
    break-inside: avoid;
    display: inline-flex;
    margin-bottom: 2.5rem;
    width: 100%;
}

.review-list__show-more {
    margin-top: 1.5rem;
    text-align: center;
}

.review-list__show-more a {
    color: var(--zumba-plum);
    display: block;
}

.review-cta {
    align-items: center;
    display: inline-grid;
    margin: 1.5rem 0;
}

.review-cta svg {
    margin-right: 0.25rem;
    width: 1.125rem;
    vertical-align: bottom;
}

.review-cta span {
    margin: 0 0.5rem;
}

.review-cta strong {
    margin-right: 0.25rem;
    text-transform: lowercase;
}

.review-cta a {
    color: var(--zumba-plum);
    margin-left: 0.5rem;
    font-size: 1rem;
}

.theme-strong .review-cta a {
    color: var(--strong-razzmatazz);
}

.no-reviews {
    text-align: center;
    margin: 1.5rem 0 0 0;
}
.no-reviews.other-instructor {
    text-align: left;
}
.no-reviews.other-instructor .reviews-count {
    margin: 1rem 0;
    display: flex;
    align-items: center;
    column-gap: 0.3rem;
}

.review-cta__self-rate-error {
    color: var(--zumba-error-red);
    margin-top: -.5rem;
    margin-bottom: 1.4rem;
}
.review-cta__self-rate-error.self-rate-spacing {
    margin-top: 1rem;
}

.review-list {
    display: grid;
    grid-template-columns: 1fr;
}
.review-list-desktop {
    display: none;
}
.review-list__show-more-desktop {
    display: none;
}
.redesign-margin-top {
    margin-top: 2rem;
}

@media only screen and (min-width: 48rem) {
    .review-list {
        display: none;
    }
    .review-list-desktop {
        display: flex;
        align-items: stretch;
        width: 100%;
    }
    .review-list__show-more {
        display: none;
    }
    .review-list__show-more-desktop {
        display: block;
        margin-top: 1.5rem;
        text-align: center;
    }
    .review-list-column {
        display: flex;
        flex-flow: column;
        margin-right: 4rem;
        width: 50%;
    }

    #instructor-reviews:target {
        scroll-margin-top: 7rem;
    }

    .review-cta {
        align-items: center;
        display: flex;
        margin: 1.5rem 0;
    }

    .review-cta a {
        color: var(--zumba-plum);
        margin: 0.12rem 0 0;
    }

}
</style>
