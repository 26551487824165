<template>
    <div
        :class="{ 'instructor-section__bg--redesign': enableRedesign && !memberView,
                  'instructor-section__bg--redesign-edit': enableRedesign && memberView}"
    >
        <section
            v-if="editMode || packs?.length"
            class="instructor-section"
            :class="{ 'instructor-section--redesign': enableRedesign }"
        >
            <div
                class="instructor-classpack-title"
                :class="{ 'instructor-classpack-title--redesign': enableRedesign && !editMode,
                          'instructor-classpack-title--redesign-edit': enableRedesign && editMode }"
            >
                <h4
                    v-if="enableRedesign"
                    class="instructor-section__title--redesign z-h1"
                >
                    {{ t.title }}
                </h4>
                <h5
                    v-else
                    class="instructor-section__title f-inter"
                >
                    {{ t.title }}
                </h5>
                <a
                    v-if="editMode"
                    :href="classPacksAction"
                    class="edit-icon"
                >
                    <EditIcon
                        :class="{ 'edit-icon--redesign': enableRedesign}"
                    />
                </a>
            </div>
            <div
                v-if="enableRedesign && !editMode"
                class="break--redesign"
            />
            <div
                class="class-packs-listing"
                :class="{ 'class-packs-listing--redesign': enableRedesign, 'few-items': packs?.length < 3 }"
            >
                <div class="carousel-items">
                    <div
                        v-if="packs?.length"
                    >
                        <div
                            :class="{ 'carousel-items--redesign': enableRedesign, 'carousel-items': !enableRedesign }"
                        >
                            <carousel
                                :class="{ 'safari': checkBrowser() }"
                                inline
                                arrows-top
                                :enable-redesign="enableRedesign"
                            >
                                <template
                                    v-for="(data, index) in packs"
                                    :key="data.id"
                                    #[`slide${index}`]
                                >
                                    <ClassPacksItem
                                        :key="data.id"
                                        :t="t"
                                        :data="data"
                                        :subtitle="packageSubtitle(data.price,data.class_quantity,data.price_currency)"
                                        :and="and"
                                    />
                                </template>
                            </carousel>
                        </div>
                    </div>
                    <div
                        v-else
                    >
                        <div class="no-classpacks-posted z-b3 f-inter">
                            {{ t.noClassPacksPosted }}
                            <a
                                class="z-b3 f-inter"
                                :href="classPacksAction"
                            >
                                {{ t.add }}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import {validateTranslations} from "@ts/Util/i18n"
import { formatCurrencyStr } from '@ts/Util/currency'
import { interpolate } from '@ts/Util/text'
import Carousel from '@components/Util/Carousel.vue'
import { default as ClassPacksItem, ClassPacksOptionData } from "@components/ClassPackages/ClassPackItem.vue"
import EditIcon from '@bx-icons/regular/bx-pencil.svg';

const translationDefaults = {
    title: "Class packs",
    each: "each",
    buy: "Buy",
    noClassPacksPosted: 'no Class Packs Posted.',
    add: 'Add'
};


export default defineComponent({
    name: 'ClassPacks',
    components: {
        ClassPacksItem,
        Carousel,
        EditIcon
    },
    props: {
        t: {
            type: Object,
            required: true,
            validator: (value : Object) => validateTranslations(value, translationDefaults)
        },
        title: {
            type: String,
            default: '',
        },
        packs: {
            type: Array as PropType<ClassPacksOptionData[]>,
            default: () => [],
            required: false
        },

        subtitle: {
            type: String,
            default: ''
        },

        editMode: {
            type: Boolean,
            default: () => false
        },

        classPacksAction: {
            type: String,
            default: '',
        },
        and: {
            type: String,
            default: 'and',
        },
        enableRedesign: {
            type: Boolean,
            default: false
        },
        memberView: {
            type: Boolean,
            default: false
        },
    },

    methods: {
        packageSubtitle(price: number, class_quantity: number,price_currency: string ): string {
            return interpolate('{0} ({1} {2})', [
                formatCurrencyStr(price, price_currency),
                formatCurrencyStr(price / class_quantity, price_currency),
                this.t.each
            ])
        },

        checkBrowser() {
            return navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1;
        },
    },
});

</script>

<style scoped>
.class-packs-listing {
    margin-top: -3em;
}

.carousel-items--redesign :deep(.item-container) {
    border: none;
}

.no-classpacks-posted {
    text-align: center;
    padding: 2.25rem 0 1.5rem;
}

.instructor-classpack-title{
    align-items: center;
    display: flex;
    margin-bottom: 2rem;
}

.instructor-classpack-title--redesign {
    flex-direction: column;
}
.instructor-classpack-title--redesign-edit {
    flex-wrap: wrap;
    justify-content: center;
}

.edit-icon {
    align-items: center;
    display: flex;
    margin: -.5rem 0 0 .5rem;
}

.edit-icon svg {
    width: 1.125rem;
    height: 1.125rem;
}

.edit-icon--redesign {
    fill: var(--zumba-white);
}

.instructor-section__bg--redesign,
.instructor-section__bg--redesign-edit {
    margin: 0 -1rem;
    background-repeat:no-repeat;
    -webkit-background-size:cover;
    -moz-background-size:cover;
    -o-background-size:cover;
    background-size:cover;
    background-position:center;
}

.instructor-section__bg--redesign-edit {
    margin: 0;
}

.theme-zumba .instructor-section__bg--redesign,
.theme-zumba .instructor-section__bg--redesign-edit {
    background-image: url("https://d29za44huniau5.cloudfront.net/uploads/2024/5/class-packs-bg-zumba-mobile.jpg");
    background-color: var(--zumba-dark-purple);
}

.theme-strong .instructor-section__bg--redesign,
.theme-strong .instructor-section__bg--redesign-edit {
    background-image: url("https://d29za44huniau5.cloudfront.net/uploads/2024/5/class-packs-bg-strong-mobile.jpg");
    background-color: var(--strong-neutral-gray-500);
}

.instructor-section--redesign {
    background: none;
    padding: 2rem 2rem 2.5rem;
    margin: 0;
}

.instructor-section__title--redesign {
    color: var(--zumba-white);
}
.theme-strong .instructor-section__title--redesign {
    font-family: var(--strong-font-uniform);
}

.theme-strong .instructor-section__title {
    font-family: var(--strong-font-default);
}

:deep(.safari .slide-item) {
    min-height: fit-content;
}

@media screen and (min-width: 48em) {
    .theme-zumba .instructor-section__bg--redesign {
        background-image: url("https://d29za44huniau5.cloudfront.net/uploads/2024/5/class-packs-bg-zumba-desktop.jpg");
    }

    .theme-strong .instructor-section__bg--redesign {
        background-image: url("https://d29za44huniau5.cloudfront.net/uploads/2024/5/class-packs-bg-strong.png");
    }

    .instructor-section--redesign {
        padding: 3.75rem 2rem;
        margin: 0 auto;
        position: relative;
    }

    .theme-zumba .instructor-section--redesign::before {
        content: '';
        display: block;
        position: absolute;
        bottom: 2.5rem;
        left: -2rem;
        width: 3rem;
        height: 3rem;
        background-image: url('https://d29za44huniau5.cloudfront.net/uploads/2024/5/class-packs-star-left.svg');
        background-repeat: no-repeat;
        background-position-y: bottom;
    }

    .theme-zumba .instructor-section--redesign::after {
        content: '';
        display: block;
        position: absolute;
        top: 6rem;
        right: -2rem;
        width: 100%;
        height: 100%;
        background-image: url('https://d29za44huniau5.cloudfront.net/uploads/2024/5/class-packs-star-right.svg');
        background-repeat: no-repeat;
        background-position-x: right;
        pointer-events: none;
    }

    .theme-strong .instructor-section__title--redesign {
        font-size: 3.125rem;
    }

    .break--redesign {
        flex-basis: 100%;
        height: 0;
    }
    .class-packs-listing--redesign {
        margin: 0 -0.45rem;
    }
    :deep(.class-packs-listing--redesign.few-items .carousel-list) {
        justify-content: center;
        width: 100%;
    }
}

@media screen and (max-width: 48em) {
    .class-packs-listing--redesign {
        margin-top: -2.5rem;
    }
    .instructor-section__title--redesign{
        font-size: 1.875rem;
        margin-bottom: 0;
    }
    .theme-zumba .instructor-section__title--redesign {
        font-weight: 700;
    }
    .theme-strong .instructor-section__title--redesign {
        font-size: 2.25rem
    }
    :deep(.carousel-list .slide-item:only-child) {
        margin-left: .5rem;
    }
}

@media only screen and (min-width: 29rem) {
    :deep(.carousel-list:nth-child(1), .carousel-list:nth-child(2)) {
        overflow-x: unset;
        .slide:first-child {
            margin-left: 0;
        }
    }

    :deep(.class-packs-listing--redesign .inline .slide) {
        max-width: 15.6rem;
        margin-left: 5.5rem;
    }

    :deep(.class-packs-listing--redesign.few-items .inline .slide) {
        max-width: 19.6rem;
        margin-left: 1.5rem;
    }

    :deep(.class-packs-listing--redesign.few-items .inline .slide:first-child) {
        margin-left: 0;
    }

    :deep(.class-packs-listing--redesign .carousel.arrows-top .control-button.next) {
        right: 0;
    }

    :deep(.class-packs-listing--redesign .carousel.arrows-top .control-button.prev) {
        right: 2.5rem;
    }

    .instructor-section__bg--redesign-edit {
        overflow: clip;
    }
}

</style>
