<template>
    <div class="vod-item">
        <div class="image">
            <img
                class="image"
                :src="photo"
                :alt="title"
            >
        </div>
        <div class="details">
            <div class="price-container">
                <div class="fitness_details">
                    <span
                        v-for="specialty in specialties"
                        :key="specialty"
                        class="z-b4 f-inter"
                    >
                        {{ allSpecialties[specialty] }}
                    </span>
                </div>
                <div class="price f-inter">
                    {{ formattedPrice }}
                </div>
            </div>
            <div class="video-details f-inter">
                <div>
                    <a
                        class="title"
                        :title="title"
                        :href="detailsUrl"
                    >
                        {{ title }}
                    </a>
                    <div class="duration f-inter">
                        {{ videoDuration }}
                    </div>
                </div>
                <ZButton
                    :variety="buttonThemed"
                    :href="buyUrl"
                    compact="always"
                    wide="always"
                    class
                >
                    {{ t.buy }}
                </ZButton>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, ComputedRef, computed } from 'vue';
import { validateTranslations } from "@ts/Util/i18n";
import ZButton from '@components/Core/ZButton.vue';
import {theme, ThemeType} from "@ts/Util/theme";
import { formatCurrencyStr } from "@ts/Util/currency";
import { currentUserLocale } from '@ts/Util/i18n';

const translationDefaults = {
    buy: "Buy",
}

export default defineComponent({
    name: 'VODItem',
    components: {
        ZButton
    },
    props: {
        t: {
            type: Object,
            default: () : Object => translationDefaults,
            validator: (value : Object) => validateTranslations(value, translationDefaults)
        },
        id: {
            type: String,
            required: true
        },
        title: {
            type: String,
            required: true,
        },
        photo: {
            type: String,
            required: true,
        },
        duration: {
            type: String,
            required: true,
        },
        price: {
            type: String,
            required: true,
        },
        currency: {
            type: String,
            required: true,
        },
        specialties: {
            type: Object,
            default: () => {}
        },
        allSpecialties: {
            type: Object,
            default: () => {}
        },
        detailsUrl: {
            type: String,
            default: '',
        },
        buyUrl: {
            type: String,
            default: '',
        },
    },
    setup(props) {
        const videoDuration: ComputedRef<string> = computed(() => {
            const hours = (parseInt(props.duration) / (60*60))
            const minutes = ((hours - Math.floor(hours)) * 60)
            const seconds = ((minutes - Math.floor(minutes)) * 60)
            const hoursF = Math.floor(hours)
            const minutesF = Math.floor(minutes)
            const secondsF = Math.floor(seconds)
            return  `${hoursF}:${('00' + minutesF).substr(-2)}:${('00' + secondsF).substr(-2)}`
        })

        const numericPrice: number = parseFloat(props.price);

        const formattedPrice : ComputedRef<string> = computed((): string => {
            return formatCurrencyStr(numericPrice || 0, props.currency, currentUserLocale(), true)
        })

        const buttonThemed = theme.value === ThemeType.Zumba ? 'rogue' : 'love';

        return {
            videoDuration,
            formattedPrice,
            buttonThemed
        }
    }
})
</script>
<style scoped>
.vod-item {
    overflow: hidden;
    border-radius: .25rem;
    margin: 0 0 1.5rem;
    width: 100%;
    border: 1px solid #DADAE2;
}

.image {
    position: relative;
    height: 11.0625rem;
}

.image img {
    width: 100%;
    display: block;
    object-fit: cover;
    height: 100%;
}

.details {
    padding: 1rem;
}
.title {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

 .theme-strong .title {
    color: var(--strong-razzmatazz);
  }

.price-container {
    display: flex;
    justify-content: space-between;
    font-size: .875rem;
}

.fitness_details  span {
    font-weight: 400;
    color: var(--zumba-gray-500);
    display: inline-block;
    vertical-align: middle;
  }

.fitness_details  span + span::before {
    background: var(--zumba-gray-500);
    border-radius: 100%;
    content: " ";
    display: inline-block;
    height: 3px;
    margin: 0 0.5rem;
    vertical-align: middle;
    width: 3px;
}


.price {
    font-weight: 700;
    color: var(--zumba-gray-800);
}
.video-details {
    display: grid;
    grid-template-columns: minmax(0, 1fr) 5rem;
    column-gap: 1rem;
    margin-top: .5rem;
}

.title {
    display: block;
    font-weight: 400;
}

.duration {
    font-size: .875rem;
    margin-top: .313rem;
    color: var(--zumba-gray-600);
    font-weight: 400;
}

@media all and (min-width: 48rem) {
    .vod-item {
        width: 31.5%;
        margin: 0 .5rem 1rem .5rem;
    }
}
</style>
