<template>
    <transition name="reveal">
        <div
            v-show="state.toShow"
            class="toast-container"
            :class="{ [`${state.positionClasses}`]: true, [`${type}`]: true }"
            :style="[cssVars]"
            @mouseover="handleMouseover"
            @mouseout="handleMouseout"
            @transitionstart="handleTransitionstart"
            @transitionend="handleTransitionend"
        >
            <div
                v-if="userDismissable"
                class="dismiss-btn"
                @click="() => dismiss()"
            >
                <CloseSvg />
            </div>

            <!-- ICON -->
            <div
                v-if="!isMobile"
                class="icon-container"
            >
                <slot name="icon">
                    <div
                        v-if="type == 'warning'"
                        class="icon-warning"
                    >
                        <div class="triangle" />
                        <span class="icon__inner">!</span>
                    </div>
                    <div
                        v-else-if="type == 'success'"
                        class="icon-success badge"
                    >
                        <CheckSvg />
                    </div>
                    <div
                        v-else-if="type == 'danger'"
                        class="icon-danger badge"
                    >
                        <CloseSvg />
                    </div>
                    <div
                        v-else-if="type == 'info'"
                        class="icon-info badge"
                    >
                        <InfoSvg />
                    </div>
                </slot>
            </div>

            <div class="toast-title-wrapper msg-container">
                <!-- TITLE -->
                <p
                    v-if="$slots.title || title"
                    class="z-b3 title"
                >
                    <slot name="title">
                        {{ title }}
                    </slot>
                </p>

                <!-- MESSAGE -->
                <p
                    class="z-b5 message"
                    :class="{
                        'no-title' : !$slots.title,
                        'f-inter' : isZumbaThemed
                    }"
                >
                    <slot name="message">
                        {{ message }}
                    </slot>
                </p>
            </div>

            <!-- CTA via text prop or SlotAPI -->
            <div class="cta-container">
                <slot
                    v-if="$slots.cta"
                    name="cta"
                />
                <button
                    v-else-if="cta && !$slots.cta"
                    class="button magneto minion cta-btn"
                    @click="handleCtaClick"
                >
                    {{ cta }}
                </button>
            </div>
        </div>
    </transition>
</template>

<script lang="ts">
import { defineComponent, reactive, computed, onMounted, watch, PropType, CSSProperties } from 'vue'
import { isMobileReactive } from '@ts/Util/responsiveness'
import { theme, ThemeType } from "@ts/Util/theme";
import CloseSvg from '@bx-icons/regular/bx-x.svg'
import CheckSvg from '@bx-icons/regular/bx-check.svg'
import InfoSvg from "@bx-icons/regular/bx-info-circle.svg"

const toastPositions = {
    topLeft: 'top-left',
    topCenter: 'top-center',
    topRight: 'top-right',
    bottomLeft: 'bottom-left',
    bottomCenter: 'bottom-center',
    bottomRight: 'bottom-right',
    middleLeft: 'middle-left',
    middleCenter: 'middle-center',
    middleRight: 'middle-right',
    standard: 'standard'
}

export type ToastPosition = 'top-left' | 'top-center' | 'top-right' |
    'bottom-left' | 'bottom-center' | 'bottom-right' |
    'middle-left' | 'middle-center' | 'middle-right' |
    'standard';

const toastTypes = ['info', 'warning', 'success', 'danger']

export type ToastType = 'info' | 'warning' | 'success' | 'danger'

type CustomStyles = {
    'toast-container'?: CSSProperties,
}

export default defineComponent({
    name: 'Toast',
    components: { CloseSvg, CheckSvg, InfoSvg },
    props: {
        title: {
            type: String,
            default: ''
        },
        message: {
            type: String,
            default: ''
        },
        // if 0, Toast will not auto-hide
        timeout: {
            type: [Number, String],
            default: 0
        },
        isVisible: {
            type: Boolean,
            default: false
        },
        userDismissable: {
            type: Boolean,
            default: true
        },
        cta: {
            type: String,
            default: ''
        },
        type: {
            type: String as PropType<ToastType>,
            default: 'info',
            validate: (value : string) : boolean => {
                return toastTypes.includes(value)
            }
        },
        customStyles: {
            type: Object as PropType<CustomStyles>,
            default: null
        },
        /**
         * Alternate positions string prop
         */
        position: {
            type: String as PropType<ToastPosition>,
            default: '',
            validate: (value : string) : boolean => {
                return Object.values(toastPositions).includes(value)
            }
        },

        /**
         * Deprecated - use position
         * @deprecated for `position`
         */
        topLeft: {
            type: Boolean,
            default: false
        },

        /**
         * Deprecated - use position
         * @deprecated for `position`
         */
        topCenter: {
            type: Boolean,
            default: false
        },

        /**
         * Deprecated - use position
         * @deprecated for `position`
         */
        topRight: {
            type: Boolean,
            default: false
        },

        /**
         * Deprecated - use position
         * @deprecated for `position`
         */
        bottomLeft: {
            type: Boolean,
            default: false
        },

        /**
         * Deprecated - use position
         * @deprecated for `position`
         */
        bottomCenter: {
            type: Boolean,
            default: false
        },

        /**
         * Deprecated - use position
         * @deprecated for `position`
         */
        bottomRight: {
            type: Boolean,
            default: false
        },
        opacity: {
            type: Number,
            default: 1,
            validate: (val : number) : boolean => {
                return val >= 0 && val <= 1
            }
        },
        noBoxShadow: {
            type: Boolean,
            default: false
        },
    },
    emits: [
        'opening',
        'opened',
        'closing',
        'closed',
        'cta:click'
    ],
    setup(props, { emit }) {
        const isMobile = isMobileReactive()
        const isZumbaThemed = theme.value === ThemeType.Zumba;
        let timeout : ReturnType<typeof setTimeout>

        const state = reactive({
            queuedToShow: props.isVisible,
            toShow: false,
            positionClasses: computed(() => {
                const classes: string[] = []
                if (props.position) {
                    const position = props.position === toastPositions.standard
                        ? (isMobile.value ? toastPositions.topCenter : toastPositions.bottomLeft)
                        : props.position
                    classes.push(position)
                } else {
                    const posProps = Object.keys(toastPositions)
                    const position = posProps.filter(key => props[key])
                    if (position.length) {
                        classes.push(toastPositions[position[0]])
                    } else {
                        classes.push('top-left')
                    }
                }
                return classes
            }),
        })

        const cssVars = computed(() => {
            let css = {}
            css['--toast-opacity'] = props.opacity
            if (props.noBoxShadow) {
                css['box-shadow'] = 'none'
            }
            if (props.customStyles) {
                Object.keys(props.customStyles).forEach(style => {
                    css = {
                        ...css,
                        ...props.customStyles[style]
                    }
                })
            }
            return css
        })

        // Set initial state after mount so it still animates in/out
        onMounted(() => {
            if (state.queuedToShow) {
                state.toShow = props.isVisible
            }
        })

        // kickstart automatic dismissal when `state.toShow` is updated (either internally or externally)
        watch(() => state.toShow, (toShow : Boolean) => {
            if (toShow && props.timeout) {
                // Auto-hide when timeout prop is present
                dismiss(props.timeout)
            } else if (!toShow && !timeout) {
                // dismiss immediately
                dismiss()
            }
        })

        // update internal state when external prop changes
        watch(props, ({ isVisible }) => {
            state.toShow = isVisible
        })

        /**
         * Hide the toast. If time is provided it will dismiss after time has elapsed
         */
        const dismiss = (time : string|number = 0) => {
            clearInterval(timeout)
            timeout = setTimeout(() => {
                state.toShow = false
            }, +time)
        }

        /**
         * Show the toast
         */
        const show = () => {
            state.toShow = true
        }

        /**
         * Handle the default CTA click
         */
        const handleCtaClick = () => {
            // provide a handler to the consumer of the event to dismiss this modal
            emit('cta:click', { dismiss })
        }

        /**
         * If user mouses over the Toast, stop the auto-hide timeout
         */
        const handleMouseover = () => {
            clearInterval(timeout)
        }

        /**
         * When user mouses out of the toast, restart the auto-hide timeout
         */
        const handleMouseout = () => {
            if (props.timeout) {
                return dismiss(props.timeout)
            }
        }

        /**
         * Handle when the toast is transitioning open/close
         */
        const handleTransitionstart = (event) => {
            if (event.propertyName == 'transform') {
                emit(state.toShow ? 'opening' : 'closing')
            }
        }

        /**
         * Handle when the toast is actually not visible (transition complete)
         */
        const handleTransitionend = (event) => {
            if (event.propertyName == 'transform') {
                emit(state.toShow ? 'opened' : 'closed')
            }
        }

        return {
            state,
            dismiss,
            show,
            handleCtaClick,
            handleMouseover,
            handleMouseout,
            handleTransitionend,
            handleTransitionstart,
            cssVars,
            isMobile,
            isZumbaThemed
        }
    }
})
</script>

<style lang="scss" scoped>

.toast-container {
    position: fixed;
    transition: all 200ms ease-in;
    background-color: var(--zumba-white);
    color: var(--zumba-white);
    padding: 1rem;
    display: flex;
    z-index: 1000000;
    opacity: var(--toast-opacity);
    flex-direction: column;
    justify-content: flex-start;

    &.success{
        background-color: var(--zumba-success-green);
    }

    &.danger{
        background-color: var(--zumba-error-red);
    }

    &.info{
        background-color: var(--zumba-light-purple);
    }
}

.theme-strong {
    .toast-container {
        &.success {
            background-color: var(--strong-alerts-green);
        }

        &.danger {
            background-color: var(--strong-alerts-red);
        }

        &.info {
            background-color: var(--strong-alerts-light-blue);
        }
    }

    .icon-info {
        fill: var(--strong-alerts-light-blue);
    }
}

.toast-container[class*="top"] {
    top: 0;
    left: 0;
    right: 0;
}

.toast-container[class*="bottom"] {
    bottom: .5rem;
    left: .5rem;
    right: .5rem;
}

.toast-container.middle-center {
    top: 50%;
    transform: translate(0, -50%);
    left: 2rem;
    right: 2rem;
}

.toast-container.middle-right,
.toast-container.middle-left {
    top:40%;
    left: 2rem;
    right: 2rem;
}

.toast-container.top-left {
    transform-origin: top left;
}

.toast-container.top-center {
    top: 0;
    transform-origin: top center;
}

.toast-container.top-right {
    transform-origin: top right;
}

.toast-container.bottom-left {
    transform-origin: bottom left;
}

.toast-container.bottom-center {
    transform-origin: bottom center;
}

.toast-container.bottom-right {
    transform-origin: bottom right;
}
.toast-container.middle-center {
    transform-origin: center;
}
.toast-container.middle-right {
    transform-origin: right;
}
.toast-container.middle-left {
    transform-origin: left;
}

.reveal-enter-from,
.reveal-leave-to {
    opacity: 0;
}

.toast-container.bottom-center.reveal-enter-from,
.toast-container.bottom-center.reveal-leave-to {
    transform: translate(0, 120%);
}

.toast-container.top-center.reveal-enter-from,
.toast-container.top-center.reveal-leave-to {
    transform: translate(0, -120%);
}

.toast-container.bottom-left.reveal-enter-from,
.toast-container.bottom-left.reveal-leave-to,
.toast-container.top-left.reveal-enter-from,
.toast-container.top-left.reveal-leave-to,
.toast-container.middle-left.reveal-enter-from,
.toast-container.middle-left.reveal-leave-to {
    transform: translate(-120%, 0);
}

.toast-container.top-right.reveal-enter-from,
.toast-container.top-right.reveal-leave-to,
.toast-container.bottom-right.reveal-enter-from,
.toast-container.bottom-right.reveal-leave-to,
.toast-container.middle-right.reveal-enter-from,
.toast-container.middle-right.reveal-leave-to {
    transform: translate(120%, 0);
}

.toast-container.middle-center.reveal-enter-from,
.toast-container.middle-center.reveal-leave-to {
    transform: translate(-200%, -50%);
}

.dismiss-btn {
    cursor: pointer;
    position: absolute;
    top: .5rem;
    right: .5rem;
}

.dismiss-btn {
    fill: var(--zumba-white);
}

.icon-container {
    min-width: 3rem;
}

.badge {
    position: relative;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    color: var(--neutral-element-light-primary);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: .8rem;
    fill: var(--zumba-success-green);
}

.icon-success {
    background-color: var(--zumba-white);
}

.icon-success svg {
    transform: scale(1);
}

.icon-danger {
    background-color: var(--zumba-white);
    fill: var(--zumba-error-red);
}

.icon-info {
    background-color: var(--zumba-white);
    fill: var(--zumba-light-purple);
}

.icon-info svg {
    transform: scale(0.8);
}

.icon-warning {
    display: flex;
    height: 1rem;
    width: 1rem;
    position: relative;
    justify-content: center;
}

.icon-warning > .icon__inner {
    position: absolute;
    z-index: 1;
    color: white;
    font-size: .75rem;
}

.triangle {
	position: absolute;
	text-align: left;
    background-color: var(--high-2);
}

.triangle::before,
.triangle::after {
	content: '';
	position: absolute;
	background-color: inherit;
}

.triangle,
.triangle::before,
.triangle::after {
	width:  .7rem;
	height: .7rem;
	border-top-right-radius: 30%;
}

.triangle {
	transform: rotate(-60deg) skewX(-30deg) scale(1,.866);
}

.triangle::before {
	transform: rotate(-135deg) skewX(-45deg) scale(1.414,.707) translate(0,-50%);
}

.triangle::after {
	transform: rotate(135deg) skewY(-45deg) scale(.707,1.414) translate(50%);
}

.title {
    font-weight: bold;
    margin: 0 0 .1rem 0;
    text-transform: uppercase;
}

.message {
    display: block;
    margin: 0 1rem 0 0;
}

.msg-container {
    display: block;
}

.cta-container {
    display: flex;
    align-items: center;
    margin-top: 1rem;
}

.cta-btn {
    min-width: 5rem;
}

@media screen and (min-width: 48rem) {
    .toast-container {
        align-items: center;
        flex-direction: row;
    }
    .toast-container[class*="top"] {
        top: 1rem;
        bottom: initial;
    }

    .toast-container[class*="bottom"] {
        bottom: 1rem;
        top: initial;
    }

    .toast-container[class*="left"] {
        left: 1rem;
        right: initial;
    }

    .toast-container[class*="right"] {
        right: 1rem;
        left: initial;
    }

    .toast-container.top-center,
    .toast-container.bottom-center {
        left: 50%;
        right: initial;
        transform: translate(-50%, 0);
    }

    .toast-container.bottom-center.reveal-enter-from,
    .toast-container.bottom-center.reveal-leave-to {
        transform: translate(-50%, 120%);
    }

    .toast-container.top-center.reveal-enter-from,
    .toast-container.top-center.reveal-leave-to {
        transform: translate(-50%, -120%);
    }

    .toast-container.top-center {
        top: .5rem;
        width: 50%;
    }

    .toast-container.bottom-left,
    .toast-container.top-left {
        width: 40%;
    }
    .toast-container.middle-right,
    .toast-container.middle-left {
        top: 45%;
    }

    .toast-container.middle-center {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: max-content;
    }

    .toast-container.middle-center.presenter {
        left: 38%;
    }

    .cta-container {
        margin-left: auto;
        margin-right: 1.5rem;
        margin-top: 0;
    }
}
</style>
