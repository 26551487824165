<template>
    <div
        class="copy-wrapper"
        :title="copyText"
    >
        <span
            v-if="label.length"
            class="label"
        >
            {{ label }}
        </span>
        <div class="text-wrapper">
            <span v-if="copying">{{ copiedT }}</span>
            <span v-else>{{ copyText }}</span>
        </div>
        <copy-icon
            v-if="!copying"
            class="icon"
            @click="copy"
        />
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import CopyIcon from '@bx-icons/regular/bx-copy.svg'

/**
 * Component that adds an "input like" box showing the URL with a built in copy button that copies text to the clipboard
 */
export default defineComponent({
    name: 'CopyTextInput',
    components: {
        CopyIcon,
    },
    props: {
        copyText: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            default: '',
        },
        copiedT: {
            type: String,
            default: 'Copied!',
        }
    },
    emits: ['copied'],
    data: () => {
        return {
            copying: false,
        }
    },
    methods: {
        copy() {
            navigator.clipboard.writeText(this.copyText)
            this.copying = true
            this.$emit('copied')
            setTimeout(() => this.copying = false, 3500)
        },
    }
})
</script>

<style scoped>
.copy-wrapper {
    background-color: var(--zumba-white);
    border-radius: .25rem;
    padding: 1rem 3rem 1rem 1rem;
    position: relative;
    border: 1px solid var(--zumba-gray-500);
}

.text-wrapper {
    overflow: hidden;
    color: var(--zumba-gray-500);
    text-overflow: ellipsis;
    user-select: all;
    white-space: nowrap;
}

@media all and (min-width: 48rem) {
    .text-wrapper {
        white-space: nowrap;
    }
}

.icon {
    position: absolute;
    right: 1.5rem;
    top: 0;
    bottom: 0;
    margin: auto 0;
    cursor: pointer;
    fill: var(--zumba-gray-400);
}

.label {
    display: block;
    margin-top:calc(-1 * .25rem);
    font-size: .7rem;
    top: 1rem;
    color: var(--zumba-gray-500);
    margin-bottom: .25rem;
}
</style>
