<template>
    <div
        class="carousel"
        :class="{ inline, 'arrows-top': arrowsTop, 'arrows-bottom': arrowsBottom }"
    >
        <button
            v-if="inline && Object.keys($slots).length > 1 && arrowsColor != 'none' && isMobile"
            :class="['control-button prev', arrowsColor, {'disabled': disabledPrevArrow}, {'control-button--redesign': enableRedesign}]"
            @click="prevSlide"
        >
            <LeftIcon
                class="control-icon"
                :class="{ 'control-icon--redesign': enableRedesign }"
            />
        </button>
        <button
            v-if="inline && Object.keys($slots).length > 3 && arrowsColor != 'none' && !isMobile"
            :class="['control-button prev', arrowsColor, {'disabled': disabledPrevArrow}, {'control-button--redesign': enableRedesign}]"
            @click="prevSlide"
        >
            <LeftIcon
                class="control-icon"
                :class="{ 'control-icon--redesign': enableRedesign }"
            />
        </button>
        <ul
            ref="carousel"
            class="carousel-list"
        >
            <li
                v-for="(slotName, index) of Object.keys($slots)"
                :key="index"
                class="slide"
            >
                <div
                    v-if="slotName.startsWith('slide')"
                    loading="lazy"
                    class="slide-item"
                >
                    <slot :name="slotName" />
                </div>
            </li>
        </ul>
        <button
            v-show="inline && Object.keys($slots).length > 1 && arrowsColor != 'none' && isMobile"
            :class="['control-button next', arrowsColor, {'disabled': disabledNextArrow}, {'control-button--redesign': enableRedesign}]"
            @click="nextSlide"
        >
            <RightIcon
                class="control-icon"
                :class="{ 'control-icon--redesign': enableRedesign }"
            />
        </button>
        <button
            v-show="inline && Object.keys($slots).length > 3 && arrowsColor != 'none' && !isMobile"
            :class="['control-button next', arrowsColor, {'disabled': disabledNextArrow}, {'control-button--redesign': enableRedesign}]"
            @click="nextSlide"
        >
            <RightIcon
                class="control-icon"
                :class="{ 'control-icon--redesign': enableRedesign }"
            />
        </button>
        <div
            v-if="!inline && Object.keys($slots).length > 1"
            class="controls"
        >
            <button
                :class="['control-button', arrowsColor, {'disabled': disabledPrevArrow}, {'control-button--redesign': enableRedesign}]"
                @click="prevSlide"
            >
                <LeftIcon
                    class="control-icon"
                    :class="{ 'control-icon--redesign': enableRedesign }"
                />
            </button>

            <button
                :class="['control-button', arrowsColor, {'control-button--redesign': enableRedesign}]"
                @click="nextSlide"
            >
                <RightIcon
                    class="control-icon"
                    :class="{ 'control-icon--redesign': enableRedesign }"
                />
            </button>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, Ref, ref } from 'vue'
import { isMobileReactive } from '@ts/Util/responsiveness'
import LeftIcon from '@bx-icons/regular/bx-left-arrow-alt.svg'
import RightIcon from '@bx-icons/regular/bx-right-arrow-alt.svg'

const behavior = 'smooth';

export default  defineComponent({
    name: 'Carousel',
    components: {
        LeftIcon,
        RightIcon,
    },
    props: {
        inline: {
            type: Boolean,
            default: false
        },
        arrowsColor: {
            type: String,
            default: 'dark',
        },
        arrowsTop: {
            type: Boolean,
            default: false,
        },
        arrowsBottom: {
            type: Boolean,
            default: false,
        },
        enableRedesign: {
            type: Boolean,
            default: false
        },
    },
    setup() {
        const carousel: Ref<HTMLUListElement|null> = ref(null)
        const isMobile = isMobileReactive()
        const disabledPrevArrow = ref(true)
        const disabledNextArrow = ref(false)

        const maxLeft = (): number => {
            const slide = <HTMLLIElement>carousel.value?.firstElementChild
            return slide.offsetWidth * carousel.value!.childElementCount
        }
        const prevSlide = (): void => {
            const slide = <HTMLLIElement>carousel.value?.firstElementChild
            let left = carousel.value!.scrollLeft - slide.offsetWidth
            disabledNextArrow.value = false
            if (left < 0) {
                disabledPrevArrow.value = true
            }
            carousel.value?.scrollTo({ left, behavior });
        }
        const nextSlide = (): void => {
            const slide = <HTMLLIElement>carousel.value?.firstElementChild
            let left = carousel.value!.scrollLeft + slide.offsetWidth
            disabledPrevArrow.value = false
            if (left + carousel.value!.offsetWidth - slide.offsetWidth >= maxLeft()) {
                disabledNextArrow.value = true
            }
            carousel.value?.scrollTo({ left, behavior });
        }

        return {
            carousel,
            isMobile,
            disabledPrevArrow,
            disabledNextArrow,
            prevSlide,
            nextSlide
        }
    },
})
</script>

<style scoped>
.carousel-list {
    margin: 0;
    padding: 0;
    scroll-snap-type: x mandatory;
    overflow-x: scroll;
    display: flex;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
}

.carousel-list::-webkit-scrollbar {
    width: 0;
    background: transparent;
}

.slide {
    list-style: none;
    align-items: center;
    flex: 1 0 90%;
    scroll-snap-align: start;
    border-radius: var(--radius-4);
}

.carousel.inline {
    display: flex;
    align-items: center;
}

.inline .slide {
    background: transparent;
}

.inline .slide:last-child {
    margin-right: 0;
}

.slide-item {
    width: 94%;
    height: 100%;
    object-fit: contain;
    display: grid;
}

.controls {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: var(--spacing-20);
}

.control-button {
    padding: 0;
    width: 2rem;
    border: none;
    background: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    border-radius: var(--radius-20);
    position: relative;
    z-index: 99;
}

.control-button.prev {
    margin-right: -1rem;
}

.control-button.next {
    margin-left: -2.2rem;
}

.control-button:disabled,
.control-button.disabled {
    cursor: default;
}

.control-icon {
    display: block;
    height: 1.5rem;
    width: 1.5rem;
    flex: 1;
}

.control-button:disabled > .control-icon,
.control-button.disabled > .control-icon {
    fill: var(--zumba-gray-400);
}

.control-button.dark:disabled, .control-button.light:disabled{
    background-color: var(--zumba-gray-400);
}

.control-button.light {
    background-color: var(--zumba-gray-300);
}

.control-button.light > .control-icon {
    fill: var(--zumba-gray-100);
}

.control-button.dark {
    background-color: var(--zumba-gray-100);
}

.control-button.dark > .control-icon {
    fill: #000c;
}

.carousel.arrows-top {
    position: relative;
    padding-top: 2rem;
}

.carousel.arrows-top .control-button {
    position: absolute;
    top: -.65rem;
    background-color: transparent;
    border: none;
}

.carousel.arrows-top .control-button.prev {
    right: 10%;
}

.carousel.arrows-top .control-button.next {
    right: -1%;
}

.carousel.arrows-top .control-icon {
    fill: var(--zumba-plum);
}

.theme-zumba .carousel.arrows-top .control-icon.control-icon--redesign {
    fill: var(--zumba-neon-green);
}

.theme-strong .carousel.arrows-top .control-icon.control-icon--redesign {
    fill: var(--zumba-white);
}

.carousel.arrows-top .control-button.disabled .control-icon {
    fill: var(--zumba-gray-400);
}

.carousel.arrows-bottom {
    position: relative;
    padding-bottom: 2rem;
}

.carousel.arrows-bottom .control-button {
    position: absolute;
    bottom: -.65rem;
    background-color: transparent;
    border: none;
}

.carousel.arrows-bottom .control-button.prev {
    right: 10%;
}

.carousel.arrows-bottom .control-button.next {
    right: -1%;
}

.carousel.arrows-bottom .control-icon {
    fill: var(--zumba-plum);
}

.theme-zumba .carousel.arrows-bottom .control-icon.control-icon--redesign {
    fill: var(--zumba-neon-green);
}

.theme-strong .carousel.arrows-bottom .control-icon.control-icon--redesign {
    fill: var(--zumba-white);
}

.carousel.arrows-bottom .control-button.disabled .control-icon {
    fill: var(--zumba-gray-400);
}

@media only screen and (min-width: 29rem) {
    .inline .slide {
        max-width: 14.6rem;
        margin-left: 6rem;
    }

    .control-button.prev {
        margin-right: -.8rem;
    }

    .carousel.arrows-top .control-button.prev {
        right: 2.8rem;
    }

    .carousel.arrows-top .control-button.next {
        right: 1%;
    }

    .carousel.arrows-bottom .control-button.prev {
        right: 2.8rem;
    }

    .carousel.arrows-bottom .control-button.next {
        right: 1%;
    }

    .carousel-items {
        margin-left: -1.5em;
    }
}

@media screen and (max-width: 48em) {
    .control-button--redesign {
        display: none;
    }

    .carousel.arrows-bottom {
        padding-bottom: 0;
    }
}
</style>
