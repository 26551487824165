<template>
    <div class="rating">
        <div class="sr-only">
            <slot
                name="screen-reader"
                :rating="state.currentRate"
                :stars="maxRating"
            >
                <span>Rated {{ state.currentRate }} stars out of {{ maxRating }}</span>
            </slot>
        </div>

        <span
            v-for="n in maxRating"
            :key="n"
            :class="[
                'rating__star',
                {
                    'rating__star--pointer': !readOnly,
                    'rating__star--selected': !isHovering && isSelected(n),
                    'rating__star--hovered': isHovered(n),
                },
            ]"
            @click="onStarRateClickHandler(n)"
            @mouseover="onStarHoverHandler(n)"
            @mouseout="onStarHoverOutHandler(n)"
        >
            <star-icon
                class="rating__star__icon"
                :width="starSize"
                :height="starSize"
            />
            <star-filled-icon
                class="rating__star__icon--filled"
                :class="{
                    [`rating__star__icon--${starColor}`] : true
                }"
                :width="starSize"
                :height="starSize"
            />
        </span>
    </div>
</template>

<script lang="ts">
import { defineComponent, reactive, watch, ref, Ref, PropType } from 'vue'
import { validateTranslations } from "@ts/Util/i18n";
import StarSvg from '@bx-icons/regular/bx-star.svg'
import StarFilledSvg from '@bx-icons/solid/bxs-star.svg'
import StarHalfFilledSvg from '@bx-icons/solid/bxs-star-half.svg'

const localT = {
    modalTitle: 'How was your class with {0}?',
};

export default defineComponent({
    name: 'Rating',
    components: {
        'star-icon': StarSvg,
        'star-filled-icon': StarFilledSvg,
        // 'star-half-filled-icon': StarHalfFilledSvg,
    },
    props: {
        t: {
            type: Object,
            default: () : Object => localT,
            validator: (value : Object) => validateTranslations(value, localT),
        },
        /**
         * Used by vue to bind the input value to `v-model` set on the component.
         */
        modelValue: {
            type: Number,
            default: 0,
        },
        readOnly: {
            type: Boolean,
            default: false
        },
        maxRating: {
            type: Number,
            default: 5,
        },
        starSize: {
            type: Number,
            default: 24,
        },
        starColor: {
            type: String as PropType<'yellow' | 'black'>,
            default: 'yellow'
        }
    },
    emits: [
        'rating:rated',
        'rating:hovered',
        'rating:hovered-out',
    ],
    setup (props, ctx) {
        const state = reactive({
            currentRate: props.modelValue,
            hoverRate: 0,
        })

        watch(() => props.modelValue, (newValue) => {
            state.currentRate = newValue
        })

        let isHovering:Ref<boolean> = ref(false)

        const isSelected = (index:number): boolean => {
            return index <= state.currentRate
        }

        const isHovered = (starRate:number): boolean => {
            return starRate <= state.hoverRate
        }

        const onStarHoverHandler = (starRate:number) => {
            if (props.readOnly) {
                return
            }
            isHovering.value = true
            state.hoverRate = starRate
            ctx.emit('rating:hovered', starRate)
        }

        const onStarHoverOutHandler = (starRate:number) => {
            if (props.readOnly) {
                return
            }
            isHovering.value = false
            state.hoverRate = 0
            ctx.emit('rating:hovered-out')
        }

        const onStarRateClickHandler = (starRate:number) => {
            if (props.readOnly) {
                return
            }
            state.currentRate = starRate
            ctx.emit('rating:rated', starRate)
        }

        return {
            state,
            isHovering,
            isSelected,
            isHovered,
            onStarHoverHandler,
            onStarHoverOutHandler,
            onStarRateClickHandler,
        }
    },
})
</script>

<style scoped>
.rating {
    text-align: center;
}

.sr-only {
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
}

.rating__star {
    display: inline-block;
}

.rating__star__icon {
    fill: var(--zumba-gray-400);
}

.rating__star__icon--filled {
    fill: var(--zumba-plum);
}

.rating__star--pointer {
    cursor: pointer;
}

.rating__star:not(.rating__star--selected):not(.rating__star--hovered) > .rating__star__icon--filled {
    display: none;
}

.rating__star--selected > .rating__star__icon,
.rating__star--hovered > .rating__star__icon {
    display: none;
}

.rating__star--selected > .rating__star__icon--filled,
.rating__star--hovered > .rating__star__icon--filled {
    display: inline;
}

.rating__star__icon--black {
    fill: var(--neutral-3);
}
</style>
