<template>
    <Modal
        :show="showModal"
        :mode="'light'"
        :mobile-drawer="true"
        :show-close-button="false"
        :custom-styles="{ modal: { 'padding': '2.5rem'} }"
        @closed="() => handleClosedModal()"
    >
        <div class="upload-profile-modal">
            <h3 class="modal-headline f-inter">
                {{ t.title }}
            </h3>
            <p class="upload-instructions">
                {{ t.instructions }}
            </p>
            <div class="main-container">
                <div class="preview-container">
                    <img
                        v-if="photoPreview"
                        :src="photoPreview"
                        :alt="photoAlt"
                        class="preview"
                    >
                    <DefaultProfilePic
                        v-else 
                        class="preview" 
                    />
                </div>
                <div class="button-container">
                    <div class="buttons">
                        <file-input
                            name="photo"
                            accept="image/png, image/jpeg, image/jpg"
                            @update:file="photoUpdated($event)"
                            @update:preview="photo.preview = $event"
                        >
                            <ZButton
                                variety="cyclops"
                                wide="mobile-only"
                            >
                                <span class="mobile-only">
                                    {{ t.add }}
                                </span>
                                <span class="desktop-only">
                                    {{ t.addPhoto }}
                                </span>
                            </ZButton>
                        </file-input>
                        <ZButton
                            variety="cyclops"
                            wide="mobile-only"
                            @click="deletePhoto()"
                        >
                            <span class="mobile-only">
                                {{ t.delete }}
                            </span>
                            <span class="desktop-only">
                                {{ t.deletePhoto }}
                            </span>
                        </ZButton>
                    </div>
                    <div class="hint">
                        {{ t.hint }}
                    </div>
                </div>
            </div>
            <horizontal-separator />
            <div class="actions-container">
                <ZButton
                    variety="phoenix"
                    wide="mobile-only"
                    @click="handleClosedModal()"
                >
                    {{ t.cancel }}
                </ZButton>
                <ZButton
                    variety="optimus"
                    wide="mobile-only"
                    :disabled="!photoChanged"
                    @click="savePhoto()"
                >
                    {{ t.save }}
                </ZButton>
            </div>
        </div>
    </Modal>
</template>
  
<script lang="ts">
import { computed, defineComponent, reactive, ref } from 'vue';
import { validateTranslations } from '@ts/Util/i18n'
import DefaultProfilePic from "@images/default-profile-pic.svg"
import Modal from '@components/Core/Modal.vue'
import FileInput from '@components/FormFields/FileInput/FileInput.vue'
import ZButton from '@components/Core/ZButton.vue';
import { profilePicThumbnail } from '@ts/Util/profile_pic';
import HorizontalSeparator from '@components/Core/HorizontalSeparator.vue'

/**
 * Value to use for empty or unchanged file, meaning there is no file to be uploaded
 */
const NoUploadFile = Symbol()

const translationDefaults = {
    instructions: 'Choose a picture that shows off your face, smile, and glowing personality. This photo will show everywhere your classes appear (including Zumba, STRONG, and other platforms).',
    title: 'Instructor Photo',
    addPhoto: 'Add Photo',
    add: 'Add',
    deletePhoto: 'Delete Photo',
    delete: 'Delete',
    save: 'Save',
    cancel: 'Cancel',
    hint: 'We recommend a square 400x400px image, .jpg or .png',
}
  
export default defineComponent({
    name: 'UploadProfilePictureModal',
    components: {
        Modal,
        DefaultProfilePic,
        FileInput,
        ZButton,
        HorizontalSeparator
    },
    props: {
        t: {
            type: Object,
            required: true,
            validator: (value : Object) => validateTranslations(value, translationDefaults)
        },
        photoUrl: {
            type: String,
            required: false,
            default: ''
        },
        photoAlt: {
            type: String,
            required: true,
        },
        showModal: {
            type: Boolean,
            required: true,
            default: false
        },
        handleClosedModal: {
            type: Function,
            required: true
        }
    },
    emits: ['update:photo'],
    setup(props, context) {
        const show = ref(props.showModal)
        const photoChanged = ref(false)
        // Set up the profile upload
        const photo = reactive({
            file: <Symbol|File>NoUploadFile,
            preview: props.photoUrl?.valueOf() || '',
            error: '',
        })
  
        const deletePhoto = () => {
            photoChanged.value = true
            photo.file = NoUploadFile
            photo.preview = ''
        }

        const savePhoto = () => {
            photoChanged.value = false
            context.emit('update:photo', photo)
        }

        const photoPreview = computed(() => {
            return photo.preview ? profilePicThumbnail(photo.preview, 80) : ''
        })

        const photoUpdated = ($event) => {
            photo.file = $event
            photoChanged.value = true
        }
  
        return {
            show,
            deletePhoto,
            photo,
            photoPreview,
            photoUpdated,
            photoChanged,
            savePhoto
        }
    },
})
</script>
  
<style scoped>

:deep(.modal) {
    width: 47rem;
}

.modal-headline {
    color: var(--zumba-gray-800);
}
  
.preview-container {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
}
  
.preview {
    width: 7.375rem;
    height: 7.375rem;
    object-fit: cover;
    border-radius: 50%;
}
  
.button-container {
    display: flex;
    margin-top: 2.125rem;
    flex-wrap: wrap;
    margin-left: 0;
}

.theme-zumba .upload-profile-modal {
    padding-left: 1rem;
    padding-right: 1rem;
}

.upload-instructions {
    font-size: 0.875rem;
    font-family: Inter;
    color: var( --zumba-gray-500);
}

.main-container {
    display: block;
}

.buttons {
    display: block;
}

.buttons :deep(.z-button.cyclops) {
    margin-right: 1rem;
}

.upload-profile-modal {
    max-width: 47.25rem;
}

.hint {
    font-size: .75rem;
    font-family: Inter;
    color: var( --zumba-gray-500);
    flex: 1 1 100%;
}

.actions-container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.actions-container > div:first-child {
    order: 2;
    margin-top: 1rem;
}

.actions-container > div:last-child {
    order: 1;
}

.mobile-only {
    display: block;
}

.desktop-only { 
    display: none;
}

@media all and (max-width: 48rem) {
    .upload-profile-modal {
        text-align: center;
    }

    .button-container :deep(.z-button), .hint {
        margin-bottom: 1.5rem;
    }

    .button-container :deep(.z-button) {
       width: 9rem;
    }
      
    .button-container > div:nth-child(1) {
        order: 3;
    }
      
    .button-container > div:nth-child(2) {
        order: 2;
    }
      
    .button-container > div:nth-child(3) {
        order: 1;
    }

    .modal-headline {
        margin-top: 0;
    }

    .buttons {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
    }
}

@media all and (min-width: 65rem) {
    .main-container {
        display: flex;
    }

    .button-container {
        display: block;
    }

    .hint {
        margin-top: 1rem;
    }

    :deep(.z-button) {
        width: 227px;
        max-width: none;
    }

    .mobile-only {
        display: none;
    }
    
    .desktop-only { 
        display: block;
    }
    .actions-container {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .actions-container > div:first-child {
        margin-top: 0;
        order: 1;
    }
    
    .actions-container > div:last-child {
        order: 2;
    }

    .theme-zumba .upload-profile-modal {
        padding-right: 1rem;
    }
    
    .button-container {
        margin-left: 1.625rem;
    }
    .buttons {
        display: inline-flex;
    }
    
    .buttons :deep(.z-button.optimus) {
        margin-right: 1rem;
    }
}

</style>