<template>
    <div class="date-picker">
        <span v-if="!!$slots?.icon">
            <slot name="icon" />
        </span>
        <CalendarIcon
            v-else
            class="date-picker__icon"
        />
        <input
            :value="selectedDate"
            v-bind="$attrs"
            type="date"
            @input="onInput"
        >
    </div>
</template>
<script setup lang="ts">
import { ref } from 'vue';
import CalendarIcon from '@bx-icons/regular/bx-calendar-alt.svg';

defineProps({
    value: {
        type: String,
        default: '',
    },
});

const emit = defineEmits(['update:modelValue'])

const selectedDate = ref('');

const onInput = (e) => {
    selectedDate.value = e.target.value;
    setTimeout(() => {
        emit('update:modelValue', selectedDate.value);
    }, 1000);
};
</script>
<style scoped>
input {
  border: 0;
  border-radius: 0;
  height: 2.5rem;
  left: -0.875rem;
  margin: 0;
  opacity: 0;
  padding: 0;
  position: absolute;
  top: -1px;
  transform: scale(2);
  width: 2.5rem;
}

.date-picker {
  height: 2.5rem;
  overflow: hidden;
  padding: 0.625rem;
  position: relative;
  width: 2.5rem;
}

.date-picker__icon {
  display: block;
  height: 1.25rem;
  width: 1.25rem;
}
</style>
