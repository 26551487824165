<template>
    <input
        ref="fileInput"
        class="input"
        :name="name"
        type="file"
        :disabled="disabled"
        :accept="accept"
        @change="onChange"
    >
    <div @click="onClick">
        <slot />
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { useUpload } from '@composables/upload'

export default defineComponent({
    name: 'FileInput',
    props: {
        name: {
            type: String,
            required: true,
        },
        accept: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        },
        /**
         * Note: only listed as a prop to allow using v-bind:file if desired for changes to the file, the prop is
         * not used internally.
         */
        file: {
            type: Object as PropType<File|null>,
            default: null,
        },
        /**
         * Note: only listed as a prop to allow using v-bind:preview if desired for changes to the file, the prop is
         * not used internally.
         */
        preview: {
            type: String,
            default: '',
        }
    },
    emits: ['update:file', 'update:preview'],
    setup(props, context) {
        const { fileInput, onClick, onChange } = useUpload({
            disabled: props.disabled,
            emit: context.emit
        })

        return {
            fileInput,
            onClick,
            onChange,
        }
    },
})
</script>

<style scoped>
.input {
    position: absolute;
    top: -9999px;
    left: -9999px;
    opacity: 0;
}
</style>
