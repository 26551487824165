<template>
    <Modal
        :show="show"
        close-when-click-on-background
        close-when-type-esc
        enable-scroll
        :mobile-drawer="mobileDrawer"
        :show-close-button="false"
        :custom-styles="customModalStyles"
        @closed="emit('closed')"
    >
        <div class="filters__header">
            <h4 class="f-inter">
                {{ t.filters }}
            </h4>
            <a
                class="filters__clear f-inter"
                href="#"
                @click.prevent="clearAndApplyFilters"
            >
                {{ t.clearAll }}
            </a>
        </div>
        <HorizontalSeparator />

        <div
            v-if="availableFilters.durations.length"
            class="filters__duration f-inter"
        >
            <h5 class="filters-section__title f-inter">
                {{ t.duration }}
            </h5>
            <div class="filters__check-group">
                <FormCheckbox
                    v-if="availableFilters.durations.includes('underThirty')"
                    v-model="selectedDurations.underThirty"
                    class="filters__check-group-option"
                    name="duration_under_thirty"
                >
                    <template #label>
                        <p class="f-inter">
                            {{ t.underThirtyMin }}
                        </p>
                    </template>
                </FormCheckbox>
                <FormCheckbox
                    v-if="availableFilters.durations.includes('thirtySixty')"
                    v-model="selectedDurations.thirtySixty"
                    class="filters__check-group-option"
                    name="duration_thirty_sixty"
                >
                    <template #label>
                        <p class="f-inter">
                            {{ t.thirtyToSixtyMin }}
                        </p>
                    </template>
                </FormCheckbox>
                <FormCheckbox
                    v-if="availableFilters.durations.includes('overSixty')"
                    v-model="selectedDurations.overSixty"
                    class="filters__check-group-option"
                    name="duration_over_sixty"
                >
                    <template #label>
                        <p class="f-inter">
                            {{ t.overSixtyMin }}
                        </p>
                    </template>
                </FormCheckbox>
            </div>
            <HorizontalSeparator />
        </div>

        <div
            v-if="availableFilters.specialties?.length"
            class="filters__fitness-type f-inter"
        >
            <h5 class="filters-section__title f-inter">
                {{ t.fitnessType }}
            </h5>
            <div class="filters__check-group">
                <template
                    v-for="(text, slug) in specialties"
                >
                    <div
                        v-if="availableFilters.specialties?.includes(slug)"
                        :key="slug"
                    >
                        <FormCheckbox
                            v-model="selectedSpecialties[slug]"
                            class="filters__check-group-option"
                            :name="`specialties_${slug.replace(/-./g, '_')}`"
                        >
                            <template #label>
                                <p class="f-inter">
                                    {{ text }}
                                </p>
                            </template>
                        </FormCheckbox>
                    </div>
                </template>
            </div>
            <HorizontalSeparator />
        </div>

        <div
            v-if="availableFilters.locations?.length"
            class="filters__location f-inter"
        >
            <h5 class="filters-section__title f-inter">
                {{ t.location }}
            </h5>
            <div class="filters__check-group">
                <FormCheckbox
                    v-model="selectedLocations.inPerson"
                    class="filters__check-group-option"
                    name="location_in_person f-inter"
                >
                    <template #label>
                        <p class="f-inter">
                            {{ t.inPerson }}
                        </p>
                    </template>
                </FormCheckbox>
                <FormCheckbox
                    v-model="selectedLocations.virtual"
                    class="filters__check-group-option"
                    name="location_virtual f-inter"
                >
                    <template #label>
                        <p class="f-inter">
                            {{ t.virtual }}
                        </p>
                    </template>
                </FormCheckbox>
            </div>
            <HorizontalSeparator />
        </div>

        <div class="filters__buttons">
            <z-button
                class="filters__buttons-button f-inter"
                wide="mobile-only"
                variety="plum"
                @click="emit('closed')"
            >
                {{ t.cancel }}
            </z-button>
            <z-button
                class="filters__buttons-button f-inter"
                wide="mobile-only"
                variety="plum"
                mode="dark"
                @click="applyFilters"
            >
                {{ t.applyFilter }}
            </z-button>
        </div>
    </Modal>
</template>

<script setup lang="ts">
import { isMobileReactive } from '@ts/Util/responsiveness'
import Modal from '@components/Core/Modal.vue'
import HorizontalSeparator from '@components/Core/HorizontalSeparator.vue'
import FormCheckbox from '@components/Core/FormCheckbox.vue'
import ZButton from '@components/Core/ZButton.vue'
import { ComputedRef, Ref, computed, ref } from 'vue'

interface ModalStyle {
    border: number;
    width: string;
    paddingTop: string;
    paddingBottom: string;
    paddingLeft: string;
    paddingRight: string;
    bottom?: string;
    left?: number;
    top?: string;
    transform?: string;
    borderRadius?: string;
}

interface SelectedLocations {
    inPerson: boolean;
    virtual: boolean;
}

const emit = defineEmits(['closed', 'apply'])

defineProps({
    show: {
        type: Boolean,
        default: () => false
    },
    specialties: {
        type: Object,
        default: () => {}
    },
    availableFilters: {
        type: Object,
        default: () => {
            return { durations: [], locations: [], specialties: [] }
        }
    },
    mobileDrawer: {
        type: Boolean,
        default: () => false
    },
    t: {
        type: Object,
        default: () => ({
            clearAll: 'Clear all',
            cancel: 'Cancel',
            filters: 'Filters',
            applyFilter: 'Apply Filter',
            duration: 'Duration',
            underThirtyMin: 'Under 30 min',
            thirtyToSixtyMin: '30 - 60 min',
            overSixtyMin: 'Over 60 min',
            fitnessType: 'Fitness Type',
            location: 'Location',
            inPerson: 'In Person',
            virtual: 'Virtual',
        })
    }
})

const customModalStyles = computed<object>(() => {
    const isMobile: Ref<boolean> = isMobileReactive()

    const modalStyle: ModalStyle = {
        border: 0,
        width: isMobile.value ? '100%' : '38rem',
        paddingTop: isMobile.value ? '1.25rem' : '2.5rem',
        paddingBottom: isMobile.value ? '1.25rem' : '2.5rem',
        paddingLeft: isMobile.value ? '1.25rem' : '2.75rem',
        paddingRight: isMobile.value ? '1.25rem' : '2.75rem'
    }

    if (isMobile.value) {
        modalStyle.bottom = '1.25rem';
        modalStyle.left = 0;
        modalStyle.top = 'initial';
        modalStyle.transform = 'none';
        modalStyle.borderRadius = '0.5rem 0.5rem 0 0';
    }

    return {
        modal: modalStyle
    }
})

const selectedDurations: Ref<any> = ref({
    underThirty: false,
    thirtySixty: false,
    overSixty: false
});

const selectedSpecialties: Ref<Object> = ref({})
const selectedLocations: Ref<SelectedLocations> = ref({
    inPerson: false,
    virtual: false,
})

const durationMinMax: ComputedRef<Array<Object>> = computed(() => {
    const minMax: Array<Object> = []
    if (selectedDurations.value.underThirty) {
        minMax.push({min: 0, max: 1800})
    }
    if (selectedDurations.value.thirtySixty) {
        minMax.push({min: 1801, max: 3600})
    }
    if (selectedDurations.value.overSixty) {
        minMax.push({min: 3601, max: 999999})
    }
    return minMax
})

const applyFilters = () : void => {
    emit('apply', {
        durations: durationMinMax.value,
        specialties: Object.keys(selectedSpecialties.value)
            .filter(k => !!selectedSpecialties.value[k]),
        locations:  Object.keys(selectedLocations.value)
            .filter(k => !!selectedLocations.value[k]),
    });

    emit('closed');
};

const clearFilters = () : void => {
    selectedSpecialties.value = {}
    Object.keys(selectedDurations.value).forEach(
        k => selectedDurations.value[k] = false
    )
    Object.keys(selectedLocations.value).forEach(
        k => selectedLocations.value[k] = false
    )
}

const clearAndApplyFilters: CallableFunction = (() : void => {
    clearFilters()
    applyFilters()
})

</script>

<style scoped>
.filters__header {
    align-items: center;
    display: flex;
}

.filters__clear {
    margin-left: auto;
}

.filters-section__title {
    margin-top: 0;
    margin-bottom: 1rem;
}

.filters__buttons {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
}

.filters__buttons-button + .filters__buttons-button {
    margin-bottom: 1rem;
}

.filters__check-group {
    display: grid;
    column-gap: 1rem;
    row-gap: 1rem;
    grid-template-columns: repeat(2, 1fr);
}

.filters__check-group-option,
.filters__check-group-option p {
    margin-bottom: 0;
}

@media only screen and (min-width: 48rem) {

    .filters__check-group {
        grid-template-columns: repeat(3, 1fr);
    }

    .filters__buttons {
        flex-direction: row;
    }

    .filters__buttons-button + .filters__buttons-button {
        margin-bottom: 0;
        margin-left: 1rem;
    }

}

</style>
