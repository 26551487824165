<template>
    <div
        class="pc-wrap"
        :class="{'pc-wrap-reversed' : reversedOrder}"
    >
        <ZInput
            v-model="priceValue"
            name="price"
            type="text"
            class="price-input"
            :class="{'with-left-margin': reversedOrder}"
            :label="priceTooltip"
            :error="internalError"
            :disabled="disabled"
            @focus="focusHandler"
            @blur="blurHandler"
        />
        <FormSelect
            v-model="currencyValue"
            class="currency-input"
            :class="{'with-left-margin': !reversedOrder}"
            name="currency"
            item-alignment="center"
            :scroll-after="10"
            :label="currencyLabel"
            :items="items"
            :disabled="disabled"
            @update:model-value="updateCurrency"
        />
    </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import FormSelect from './FormSelect.vue';
import ZInput from './ZInput.vue';
import { supportedCurrencies, minimumPricePerCurrency, formatCurrencyStr, formatCurrencyParts, DEFAULT_CURRENCY } from '../../ts/Util/currency'

type CurrencyItems = Record<string, string>

const DEFAULT_ERRORS = {
    required: (label : string) : string => {
        return `${label} is required.`
    },
    minimum: (label : string, min : number) : string => {
        return `${label} must be at least ${min}.`
    },
    invalid: (label: string) : string => {
        return `Please enter a valid ${label}`
    }
}

const props = defineProps({
    priceLabel: {
        type: String,
        default: "Price"
    },
    priceTooltip: {
        type: String,
        default: "price"
    },
    currencyLabel: {
        type: String,
        default: "Currency"
    },
    currencies: {
        type: Array,
        default: () : Array<string> => supportedCurrencies
    },
    /** Used by vue to bind the input value to `v-model:currency` on the component. */
    currency: {
        type: String,
        default: DEFAULT_CURRENCY
    },
    /* Used by vue to bind the input value to `v-model:price` set on the component. */
    price: {
        type: [Number, String],
        default: ''
    },
    required: {
        type: Boolean,
        default: false
    },
    error: {
        type: String,
        default: ""
    },
    disabled: {
        type: Boolean,
        default: false
    },
    reversedOrder: {
        type: Boolean,
        default: false
    }
})

const emit = defineEmits(['update:currency', 'update:price', 'price:focus', 'price:blur'])

const focusHandler = ($event): void => {
    if (parseFloat(priceValue.value) == 0.0) {
        priceValue.value = '';
    }
    emit('price:focus', $event)
}

const blurHandler = ($event) : void => {
    priceValue.value = formatPrice(priceValue.value)
    emit('price:blur', $event)
}

const currencyDetails = (type : string) : { type: string, value: any } => {
    let parts = formatCurrencyParts(1, currencyValue.value)
    return parts.find(part => part.type == type)
}

const stripNonNumbers = (price : string) => {
    let decimal = currencyDetails('decimal')
    if (typeof decimal === 'undefined') {
        return ''
    }
    let nonNumber = new RegExp(`[^0-9\\${decimal.value}]`, 'g');
    price = price.replace(nonNumber, "")
    if (price == decimal.value) {
        return ''
    }
    return price
}

const formatPrice = (price : string | number) : string => {
    if (typeof price === 'string') {
        price = stripNonNumbers(price)
        if (price == '') {
            return ''
        }
        price = parseFloat(price)
    }

    let symbol = currencyDetails('currency')
    if (typeof symbol === 'undefined') {
        return ''
    }

    return formatCurrencyStr(price, currencyValue.value).replace(symbol.value, '').trim()
}

const updateCurrency = () : void => {
    emit('update:currency', currencyValue)
}

const currencyValue = ref(<string>props.currency)
const priceValue = ref(<string>formatPrice(props.price))
const internalError = ref(<string>props.error)
const minPricePerCurrency = ref(minimumPricePerCurrency)

const items = computed((): CurrencyItems => {
    let currencies = {}
    for (let i = 0; i < props.currencies.length; i++) {
        let currency : string = <string>props.currencies[i]
        currencies[currency] = currency
    }
    return currencies
})

watch(() => props.currency, (newCurrency) => {
    currencyValue.value = newCurrency;
})

watch(() => priceValue.value, (newPrice, oldPrice) => {
    let formatted = formatPrice(newPrice)
    let value = parseFloat(stripNonNumbers(formatted))

    if (props.required) {
        if (newPrice == '') {
            internalError.value = DEFAULT_ERRORS.required(props.priceLabel)
        } else if (isNaN(value)) {
            internalError.value = DEFAULT_ERRORS.invalid(props.priceLabel)
        } else if (minPricePerCurrency.value[currencyValue.value] > value) {
            internalError.value = DEFAULT_ERRORS.minimum(props.priceLabel, minPricePerCurrency.value[currencyValue.value])
        } else {
            internalError.value = props.error
        }
    }

    if (newPrice !== oldPrice) {
        emit('update:price', Number.isNaN(value) ? null : value)
    }
})

watch(() => props.error, (newError : string) => {
    internalError.value = newError
})
</script>

<style scoped>
    .pc-wrap {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
    }
    .pc-wrap-reversed {
        flex-direction: row-reverse;
    }
    .with-left-margin {
        margin-left: 1rem;
    }

    .price-input {
        flex: 65%;
    }

    .currency-input {
        flex: 35%;
    }

    .fade-in-enter-from,
    .fade-in-leave-to {
        opacity: 0;
    }
</style>
